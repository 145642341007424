:root {
	--blue:#4e73df;
	--indigo:#6610f2;
	--purple:#6f42c1;
	--pink:#e83e8c;
	--red:#e74a3b;
	--orange:#fd7e14;
	--yellow:#dea20b;
	--green:#1cc88a;
	--teal:#20c9a6;
	--cyan:#36b9cc;
	--white:#fff;
	--gray:#858796;
	--gray-dark:#5a5c69;
	--primary:#4e73df;
	--secondary:#858796;
	--success:#1cc88a;
	--info:#36b9cc;
	--warning:#dea20b;
	--danger:#e74a3b;
	--light:#f8f9fc;
	--dark:#5a5c69;
	--breakpoint-xs:0;
	--breakpoint-sm:576px;
	--breakpoint-md:768px;
	--breakpoint-lg:992px;
	--breakpoint-xl:1200px;
	--font-family-sans-serif:"Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--font-family-monospace:SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace 
}
*, ::after, ::before {
	box-sizing:border-box 
}
html {
	font-family:'Inter',sans-serif;
	line-height:1.15;
	-webkit-text-size-adjust:100%;
	-webkit-tap-highlight-color:transparent 
}
header, nav {
	display:block 
}
body {
	margin:0;
	font-family:'Inter',sans-serif;
	font-size:1rem;
	font-weight:400;
	line-height:1.5;
	color:#333;
	text-align:left;
	background-color: #f9fafd;
}
[tabindex="-1"]:focus {
	outline:0 !important 
}
hr {
	box-sizing:content-box;
	height:0;
	overflow:visible 
}
h1, h2, h3, h4, h5, h6 {
	margin-top:0;
	margin-bottom:.5rem 
}
p {
	margin-top:0;
	margin-bottom:1rem 
}
dl, ol, ul {
	margin-top:0;
	margin-bottom:1rem 
}
ol ol, ol ul, ul ol, ul ul {
	margin-bottom:0 
}
b, strong {
	font-weight:600 
}
a {
	color:#4e73df;
	text-decoration:none;
	background-color:transparent 
}
a:hover {
	color:#224abe;
	text-decoration:underline 
}
a:not([href]):not([tabindex]) {
	color:inherit;
	text-decoration:none 
}
a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
	color:inherit;
	text-decoration:none 
}
a:not([href]):not([tabindex]):focus {
	outline:0 
}
img {
	vertical-align:middle;
	border-style:none 
}
svg {
	overflow:hidden;
	vertical-align:middle 
}
table {
	border-collapse:collapse 
}
th {
	text-align:inherit 
}
label {
	display:inline-block;
	margin-bottom:.5rem 
}
button {
	border-radius:0 
}
button:focus {
	outline:1px dotted;
	outline:5px auto -webkit-focus-ring-color 
}
button, input, optgroup, select, textarea {
	margin:0;
	font-family:inherit;
	font-size:inherit;
	line-height:inherit 
}
button, input {
	overflow:visible 
}
button, select {
	text-transform:none 
}
select {
	word-wrap:normal 
}
[type=button], [type=reset], [type=submit], button {
	appearance:button;
	-webkit-appearance:button;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
	cursor:pointer 
}
[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
	padding:0;
	border-style:none 
}
input[type=checkbox], input[type=radio] {
	box-sizing:border-box;
	padding:0 
}
input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
	appearance:listbox;
	-webkit-appearance:listbox;
}
textarea {
	overflow:auto;
	resize:vertical 
}
fieldset {
	border: 1px solid #e3e6f0;
	padding: 0rem 0.75rem;
}
[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
	height:auto 
}
[type=search] {
	outline-offset:-2px;
	-webkit-appearance:none;
   appearance:none;
}
[type=search]::-webkit-search-decoration {
	-webkit-appearance:none 
}
::-webkit-file-upload-button {
	font:inherit;
	-webkit-appearance:button 
}
[hidden] {
	display:none !important 
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	margin-bottom:.5rem;
	font-weight:400;
	line-height:1.2 
}
.h1, h1 {
	font-size:2.5rem 
}
.h2, h2 {
	font-size:2rem 
}
.h3, h3 {
	font-size:1.75rem 
}
.h4, h4 {
	font-size:1.5rem 
}
.h5, h5 {
	font-size:1.2rem 
}
.h6, h6 {
	font-size:1rem 
}
hr {
	margin-top:1rem;
	margin-bottom:1rem;
	border:0;
	border-top:1px solid rgba(0, 0, 0, 0.23) 
}
.small, small {
	font-size:80%;
	font-weight:400 
}
.img-fluid {
	max-width:100%;
	height:auto 
}
.container {
	width:100%;
	padding-right:.75rem;
	padding-left:.75rem;
	margin-right:auto;
	margin-left:auto 
}
@media (min-width:576px) {
	.container {
		max-width:540px 
   }
}
@media (min-width:768px) {
	.container {
		max-width:720px 
   }
}
@media (min-width:992px) {
	.container {
		max-width:960px 
   }
}
@media (min-width:1200px) {
	.container {
		max-width:1140px 
   }
}
.container-fluid {
	width:100%;
	padding-right:.75rem;
	padding-left:.75rem;
	margin-right:auto;
	margin-left:auto 
}
.row {
	display:flex;
	flex-wrap:wrap;
	margin-right:-.75rem;
	margin-left:-.75rem 
}
.no-gutters {
	margin-right:0;
	margin-left:0 
}
.no-gutters>.col, .no-gutters>[class*=col-] {
	padding-right:0;
	padding-left:0 
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
	position:relative;
	width:100%;
	padding-right:.75rem;
	padding-left:.75rem 
}
.col {
	flex-basis:0;
	flex-grow:1;
	max-width:100% 
}
.col-auto {
	flex:0 0 auto;
	width:auto;
	max-width:100% 
}
.col-1 {
	flex:0 0 8.33333%;
	max-width:8.33333% 
}
.col-2 {
	flex:0 0 16.66667%;
	max-width:16.66667% 
}
.col-3 {
	flex:0 0 25%;
	max-width:25% 
}
.col-4 {
	flex:0 0 33.33333%;
	max-width:33.33333% 
}
.col-5 {
	flex:0 0 41.66667%;
	max-width:41.66667% 
}
.col-6 {
	flex:0 0 50%;
	max-width:50% 
}
.col-7 {
	flex:0 0 58.33333%;
	max-width:58.33333% 
}
.col-8 {
	flex:0 0 66.66667%;
	max-width:66.66667% 
}
.col-9 {
	flex:0 0 75%;
	max-width:75% 
}
.col-10 {
	flex:0 0 83.33333%;
	max-width:83.33333% 
}
.col-11 {
	flex:0 0 91.66667%;
	max-width:91.66667% 
}
.col-12 {
	flex:0 0 100%;
	max-width:100% 
}
@media (min-width:576px) {
	.col-sm {
		flex-basis:0;
		flex-grow:1;
		max-width:100% 
   }
	.col-sm-auto {
		flex:0 0 auto;
		width:auto;
		max-width:100% 
   }
	.col-sm-1 {
		flex:0 0 8.33333%;
		max-width:8.33333% 
   }
	.col-sm-2 {
		flex:0 0 16.66667%;
		max-width:16.66667% 
   }
	.col-sm-3 {
		flex:0 0 25%;
		max-width:25% 
   }
	.col-sm-4 {
		flex:0 0 33.33333%;
		max-width:33.33333% 
   }
	.col-sm-5 {
		flex:0 0 41.66667%;
		max-width:41.66667% 
   }
	.col-sm-6 {
		flex:0 0 50%;
		max-width:50% 
   }
	.col-sm-7 {
		flex:0 0 58.33333%;
		max-width:58.33333% 
   }
	.col-sm-8 {
		flex:0 0 66.66667%;
		max-width:66.66667% 
   }
	.col-sm-9 {
		flex:0 0 75%;
		max-width:75% 
   }
	.col-sm-10 {
		flex:0 0 83.33333%;
		max-width:83.33333% 
   }
	.col-sm-11 {
		flex:0 0 91.66667%;
		max-width:91.66667% 
   }
	.col-sm-12 {
		flex:0 0 100%;
		max-width:100% 
   }
}
@media (min-width:768px) {
	.col-md {
		flex-basis:0;
		flex-grow:1;
		max-width:100% 
   }
	.col-md-auto {
		flex:0 0 auto;
		width:auto;
		max-width:100% 
   }
	.col-md-1 {
		flex:0 0 8.33333%;
		max-width:8.33333% 
   }
	.col-md-2 {
		flex:0 0 16.66667%;
		max-width:16.66667% 
   }
	.col-md-3 {
		flex:0 0 25%;
		max-width:25% 
   }
	.col-md-4 {
		flex:0 0 33.33333%;
		max-width:33.33333% 
   }
	.col-md-5 {
		flex:0 0 41.66667%;
		max-width:41.66667% 
   }
	.col-md-6 {
		flex:0 0 50%;
		max-width:50% 
   }
	.col-md-7 {
		flex:0 0 58.33333%;
		max-width:58.33333% 
   }
	.col-md-8 {
		flex:0 0 66.66667%;
		max-width:66.66667% 
   }
	.col-md-9 {
		flex:0 0 75%;
		max-width:75% 
   }
	.col-md-10 {
		flex:0 0 83.33333%;
		max-width:83.33333% 
   }
	.col-md-11 {
		flex:0 0 91.66667%;
		max-width:91.66667% 
   }
	.col-md-12 {
		flex:0 0 100%;
		max-width:100% 
   }
}
@media (min-width:992px) {
	.col-lg {
		flex-basis:0;
		flex-grow:1;
		max-width:100% 
   }
	.col-lg-auto {
		flex:0 0 auto;
		width:auto;
		max-width:100% 
   }
	.col-lg-1 {
		flex:0 0 8.33333%;
		max-width:8.33333% 
   }
	.col-lg-2 {
		flex:0 0 16.66667%;
		max-width:16.66667% 
   }
	.col-lg-3 {
		flex:0 0 25%;
		max-width:25% 
   }
	.col-lg-4 {
		flex:0 0 33.33333%;
		max-width:33.33333% 
   }
	.col-lg-5 {
		flex:0 0 41.66667%;
		max-width:41.66667% 
   }
	.col-lg-6 {
		flex:0 0 50%;
		max-width:50% 
   }
	.col-lg-7 {
		flex:0 0 58.33333%;
		max-width:58.33333% 
   }
	.col-lg-8 {
		flex:0 0 66.66667%;
		max-width:66.66667% 
   }
	.col-lg-9 {
		flex:0 0 75%;
		max-width:75% 
   }
	.col-lg-10 {
		flex:0 0 83.33333%;
		max-width:83.33333% 
   }
	.col-lg-11 {
		flex:0 0 91.66667%;
		max-width:91.66667% 
   }
	.col-lg-12 {
		flex:0 0 100%;
		max-width:100% 
   }
}
@media (min-width:1200px) {
	.col-xl {
		flex-basis:0;
		flex-grow:1;
		max-width:100% 
   }
	.col-xl-auto {
		flex:0 0 auto;
		width:auto;
		max-width:100% 
   }
	.col-xl-1 {
		flex:0 0 8.33333%;
		max-width:8.33333% 
   }
	.col-xl-2 {
		flex:0 0 16.66667%;
		max-width:16.66667% 
   }
	.col-xl-3 {
		flex:0 0 25%;
		max-width:25% 
   }
	.col-xl-4 {
		flex:0 0 33.33333%;
		max-width:33.33333% 
   }
	.col-xl-5 {
		flex:0 0 41.66667%;
		max-width:41.66667% 
   }
	.col-xl-6 {
		flex:0 0 50%;
		max-width:50% 
   }
	.col-xl-7 {
		flex:0 0 58.33333%;
		max-width:58.33333% 
   }
	.col-xl-8 {
		flex:0 0 66.66667%;
		max-width:66.66667% 
   }
	.col-xl-9 {
		flex:0 0 75%;
		max-width:75% 
   }
	.col-xl-10 {
		flex:0 0 83.33333%;
		max-width:83.33333% 
   }
	.col-xl-11 {
		flex:0 0 91.66667%;
		max-width:91.66667% 
   }
	.col-xl-12 {
		flex:0 0 100%;
		max-width:100% 
   }
}
.table {
	width:100%;
	margin-bottom:1rem;
	color:#191c34;
	min-height: 190px;
	font-size: 15px;
}
.table td, .table th {
	padding:.5rem;
	vertical-align:top;
	border-top:1px solid #e3e6f0 
}
.table thead th {
	vertical-align:top;
}
.table tbody+tbody {
	border-top:2px solid #e3e6f0 
}
.table-sm td, .table-sm th {
	padding:.3rem 
}
.table-bordered {
	border:1px solid #e3e6f0 
}
.table-bordered td, .table-bordered th {
	border:1px solid #e3e6f0 
}
.table-bordered thead td, .table-bordered thead th {
	border-bottom-width:2px 
}
.table-hover tbody tr:hover {
	background-color:rgba(0, 0, 0, .02) 
}
@media (max-width:575.98px) {
	.table-responsive-sm {
		display:block;
		width:100%;
		overflow-x:auto;
		-webkit-overflow-scrolling:touch 
   }
	.table-responsive-sm>.table-bordered {
		border:0 
   }
}
@media (max-width:767.98px) {
	.table-responsive-md {
		display:block;
		width:100%;
		overflow-x:auto;
		-webkit-overflow-scrolling:touch 
   }
	.table-responsive-md>.table-bordered {
		border:0 
   }
}
@media (max-width:991.98px) {
	.table-responsive-lg {
		display:block;
		width:100%;
		overflow-x:auto;
		-webkit-overflow-scrolling:touch 
   }
	.table-responsive-lg>.table-bordered {
		border:0 
   }
}
@media (max-width:1199.98px) {
	.table-responsive-xl {
		display:block;
		width:100%;
		overflow-x:auto;
		-webkit-overflow-scrolling:touch 
   }
	.table-responsive-xl>.table-bordered {
		border:0 
   }
}
.table-responsive {
	display:block;
	width:100%;
	overflow-x:auto;
	-webkit-overflow-scrolling:touch;
}
.table-responsive>.table-bordered {
	border:0 
}
.form-control {
	display:block;
	width:100%;
	height:calc(1.5em + .75rem + 2px);
	padding:.375rem .75rem;
	font-size:1rem;
	font-weight:400;
	line-height:1.5;
	color:#222222;
	background-color:#fff;
	background-clip:padding-box;
	border:1px solid #d1d3e2;
	border-radius:.35rem;
	transition:border-color .15s ease-in-out, box-shadow .15s ease-in-out 
}
@media (prefers-reduced-motion:reduce) {
	.form-control {
		transition:none 
   }
}
.form-control::-ms-expand {
	background-color:transparent;
	border:0 
}
.form-control:focus {
	color:#222222;
	background-color:#fff;
	border-color:#bac8f3;
	outline:0;
	box-shadow:0 0 0.1rem .075rem rgba(78, 115, 223, .25) 
}
.form-control::-webkit-input-placeholder {
	color:#858796;
	opacity:1 
}
.form-control::-moz-placeholder {
	color:#858796;
	opacity:1 
}
.form-control:-ms-input-placeholder {
	color:#858796;
	opacity:1 
}
.form-control::-ms-input-placeholder {
	color:#858796;
	opacity:1 
}
.form-control::placeholder {
	color:#858796;
	opacity:1 
}
.form-control:disabled, .form-control[readonly] {
	background-color:#eaecf4;
	opacity:1 
}
select.form-control:focus::-ms-value {
	color:#222222;
	background-color:#fff 
}
.form-control-plaintext {
	display:block;
	width:100%;
	padding-top:.375rem;
	padding-bottom:.375rem;
	margin-bottom:0;
	line-height:1.5;
	color:#858796;
	background-color:transparent;
	border:solid transparent;
	border-width:1px 0 
}
.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
	padding-right:0;
	padding-left:0 
}
.form-control-sm {
	height:calc(1.5em + .5rem + 2px);
	padding:.25rem .5rem;
	font-size:.875rem;
	line-height:1.5;
	border-radius:.2rem 
}
.form-control-lg {
	height:calc(1.5em + 1rem + 2px);
	padding:.5rem 1rem;
	font-size:1.25rem;
	line-height:1.5;
	border-radius:.3rem 
}
select.form-control[multiple], select.form-control[size] {
	height:auto 
}
textarea.form-control {
	height:auto 
}
.form-group {
	margin-bottom:1rem 
}
.valid-feedback {
	display:none;
	width:100%;
	margin-top:.25rem;
	font-size:80%;
	color:#1cc88a 
}
.valid-tooltip {
	position:absolute;
	top:100%;
	z-index:5;
	display:none;
	max-width:100%;
	padding:.25rem .5rem;
	margin-top:.1rem;
	font-size:.875rem;
	line-height:1.5;
	color:#fff;
	background-color:rgba(28, 200, 138, .9);
	border-radius:.35rem 
}
.form-control.is-valid, .was-validated .form-control:valid {
	border-color:#1cc88a;
	padding-right:calc(1.5em + .75rem);
	background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231cc88a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat:no-repeat;
	background-position:center right calc(.375em + .1875rem);
	background-size:calc(.75em + .375rem) calc(.75em + .375rem) 
}
.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
	border-color:#1cc88a;
	box-shadow:0 0 0 .2rem rgba(28, 200, 138, .25) 
}
.form-control.is-valid~.valid-feedback, .form-control.is-valid~.valid-tooltip, .was-validated .form-control:valid~.valid-feedback, .was-validated .form-control:valid~.valid-tooltip {
	display:block 
}
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
	padding-right:calc(1.5em + .75rem);
	background-position:top calc(.375em + .1875rem) right calc(.375em + .1875rem) 
}
.invalid-feedback {
	display:none;
	width:100%;
	margin-top:.25rem;
	font-size:80%;
	color:#e74a3b 
}
.invalid-tooltip {
	position:absolute;
	top:100%;
	z-index:5;
	display:none;
	max-width:100%;
	padding:.25rem .5rem;
	margin-top:.1rem;
	font-size:.875rem;
	line-height:1.5;
	color:#fff;
	background-color:rgba(231, 74, 59, .9);
	border-radius:.35rem 
}
.form-control.is-invalid, .was-validated .form-control:invalid {
	border-color:#e74a3b;
	padding-right:calc(1.5em + .75rem);
	background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e74a3b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e74a3b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
	background-repeat:no-repeat;
	background-position:center right calc(.375em + .1875rem);
	background-size:calc(.75em + .375rem) calc(.75em + .375rem) 
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
	border-color:#e74a3b;
	box-shadow:0 0 0 .2rem rgba(231, 74, 59, .25) 
}
.form-control.is-invalid~.invalid-feedback, .form-control.is-invalid~.invalid-tooltip, .was-validated .form-control:invalid~.invalid-feedback, .was-validated .form-control:invalid~.invalid-tooltip {
	display:block 
}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
	padding-right:calc(1.5em + .75rem);
	background-position:top calc(.375em + .1875rem) right calc(.375em + .1875rem) 
}
.btn {
	display:inline-block;
	font-weight:400;
	color:#858796;
	text-align:center;
	vertical-align:middle;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
	background-color:transparent;
	border:1px solid transparent;
	padding:.375rem .75rem;
	font-size:1rem;
	line-height:1.5;
	border-radius:.35rem;
	transition:color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out 
}
@media (prefers-reduced-motion:reduce) {
	.btn {
		transition:none 
   }
}
.btn:hover {
	color:#858796;
	text-decoration:none 
}
.btn.focus, .btn:focus {
	outline:0;
	box-shadow:0 0 0 .2rem rgba(78, 115, 223, .25) 
}
.btn.disabled, .btn:disabled {
	opacity:.65 
}
a.btn.disabled, fieldset:disabled a.btn {
	pointer-events:none 
}
.btn-primary {
	color: #fff;
	background-color: #01315a;
	border-color: #012b4e;
}
.btn-primary:hover {
	color:#fff;
	background-color:#012b4e;
	border-color:#012b4e 
}
.btn-primary.focus, .btn-primary:focus {
	box-shadow:0 0 0 .2rem rgba(105, 136, 228, .5) 
}
.btn-primary.disabled, .btn-primary:disabled {
	color:#fff;
	background-color:#014073;
	border-color:#4e73df 
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active {
	color:#fff;
	background-color:#012b4e;
	border-color:#012b4e 
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
	box-shadow:0 0 0 .2rem rgba(105, 136, 228, .5) 
}
.btn-secondary {
	color:#fff;
	background-color:#fdb100;
	border-color:#fdb100 
}
.btn-secondary:hover {
	color:#fff;
	background-color:#e9a301;
	border-color:#e9a301 
}
.btn-secondary.focus, .btn-secondary:focus {
	box-shadow:0 0 0 .2rem rgba(151, 153, 166, .5) 
}
.btn-secondary.disabled, .btn-secondary:disabled {
	color:#fff;
	background-color:#858796;
	border-color:#858796 
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active {
	color:#fff;
	background-color:#e9a301;
	border-color:#e9a301 
}
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus {
	box-shadow:0 0 0 .2rem rgba(151, 153, 166, .5) 
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
	color:#fff;
	background-color:#e9a301;
	border-color:#e9a301 
}
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus {
	box-shadow:0 0 0 .2rem rgba(151, 153, 166, .5) 
}
.btn-success {
	color: #fff;
	background-color: #058343;
	border-color: #047c3f;
}
.btn-success:hover {
	color:#fff;
	background-color:#026633;
	border-color:#026633 
}
.btn-success.focus, .btn-success:focus {
	box-shadow:0 0 0 .2rem rgba(62, 208, 156, .5) 
}
.btn-success.disabled, .btn-success:disabled {
	color:#fff;
	background-color:#1cc88a;
	border-color:#1cc88a 
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active {
	color:#fff;
	background-color:#026633;
	border-color:#026633 
}
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus {
	box-shadow:0 0 0 .2rem rgba(62, 208, 156, .5) 
}
.btn-info {
	color: #fff;
	background-color: #06897c;
	border-color: #058376;
}
.btn-info:hover {
	color:#fff;
	background-color:#038174;
	border-color:#038174 
}
.btn-info.focus, .btn-info:focus {
	box-shadow:0 0 0 .2rem rgba(84, 196, 212, .5) 
}
.btn-info.disabled, .btn-info:disabled {
	color:#fff;
	background-color:#36b9cc;
	border-color:#36b9cc 
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active {
	color:#fff;
	background-color:#038174;
	border-color:#038174 
}
.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus {
	box-shadow:0 0 0 .2rem rgba(84, 196, 212, .5) 
}
.btn-warning {
	color:#fff;
	background-color: #de760b;
	border-color: #db7409;
}
.btn-warning:hover {
	color:#fff;
	background-color:#c96906;
	border-color:#c96906 
}
.btn-warning.focus, .btn-warning:focus {
	box-shadow:0 0 0 .2rem rgba(247, 203, 91, .5) 
}
.btn-warning.disabled, .btn-warning:disabled {
	color:#fff;
	background-color:#dea20b;
	border-color:#dea20b 
}
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active {
	color:#fff;
	background-color:#c96906;
	border-color:#c96906 
}
.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus {
	box-shadow:0 0 0 .2rem rgba(247, 203, 91, .5) 
}
.btn-danger {
	color: #fff;
	background-color: #ad1405;
	border-color: #a51304;
}
.btn-danger:hover {
	color:#fff;
	background-color:#9c1205;
	border-color:#9c1205 
}
.btn-danger.focus, .btn-danger:focus {
	box-shadow:0 0 0 .2rem rgba(235, 101, 88, .5) 
}
.btn-danger.disabled, .btn-danger:disabled {
	color:#fff;
	background-color:#e74a3b;
	border-color:#e74a3b 
}
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active {
	color:#fff;
	background-color:#9c1205;
	border-color:#9c1205 
}
.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus {
	box-shadow:0 0 0 .2rem rgba(235, 101, 88, .5) 
}
.btn-light {
	color:#3a3b45;
	background-color:#eaeef9;
	border-color:#eaeef9 
}
.btn-light:hover {
	color:#3a3b45;
	background-color:#dde2f1;
	border-color:#d4daed 
}
.btn-light.focus, .btn-light:focus {
	box-shadow:0 0 0 .2rem rgba(220, 221, 225, .5) 
}
.btn-light.disabled, .btn-light:disabled {
	color:#3a3b45;
	background-color:#eaeef9;
	border-color:#eaeef9 
}
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active {
	color:#3a3b45;
	background-color:#d4daed;
	border-color:#cbd3e9 
}
.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus {
	box-shadow:0 0 0 .2rem rgba(220, 221, 225, .5) 
}
.btn-dark {
	color:#fff;
	background-color:#5a5c69;
	border-color:#5a5c69 
}
.btn-dark:hover {
	color:#fff;
	background-color:#484a54;
	border-color:#42444e 
}
.btn-dark.focus, .btn-dark:focus {
	box-shadow:0 0 0 .2rem rgba(115, 116, 128, .5) 
}
.btn-dark.disabled, .btn-dark:disabled {
	color:#fff;
	background-color:#5a5c69;
	border-color:#5a5c69 
}
.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active {
	color:#fff;
	background-color:#42444e;
	border-color:#3d3e47 
}
.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus {
	box-shadow:0 0 0 .2rem rgba(115, 116, 128, .5) 
}
.btn-link {
	font-weight:400;
	color:#4e73df;
	text-decoration:none 
}
.btn-link:hover {
	color:#224abe;
	text-decoration:underline 
}
.btn-link.focus, .btn-link:focus {
	text-decoration:underline;
	box-shadow:none 
}
.btn-link.disabled, .btn-link:disabled {
	color:#858796;
	pointer-events:none 
}
.btn-lg {
	padding:.5rem 1rem;
	font-size:1.25rem;
	line-height:1.5;
	border-radius:.3rem 
}
.btn-sm {
	padding:.25rem .5rem;
	font-size:.875rem;
	line-height:1.5;
	border-radius:.2rem 
}
.btn-block {
	display:block;
	width:100% 
}
.btn-block+.btn-block {
	margin-top:.5rem 
}
input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
	width:100% 
}
.fade {
	transition:opacity .15s linear 
}
@media (prefers-reduced-motion:reduce) {
	.fade {
		transition:none 
   }
}
.fade:not(.show) {
	opacity:0 
}
.collapse:not(.show) {
	display:none 
}
.collapsing {
	position:relative;
	height:0;
	overflow:hidden;
	transition:height .15s ease 
}
@media (prefers-reduced-motion:reduce) {
	.collapsing {
		transition:none 
   }
}
.dropdown, .dropleft, .dropright, .dropup {
	position:relative 
}
.dropdown-toggle {
	white-space:nowrap 
}
.dropdown-toggle::after {
	display:inline-block;
	margin-left:.255em;
	vertical-align:.255em;
	content:"";
	border-top:.3em solid;
	border-right:.3em solid transparent;
	border-bottom:0;
	border-left:.3em solid transparent 
}
.dropdown-toggle:empty::after {
	margin-left:0 
}
.dropdown-menu {
	position:absolute;
	top:100%;
	left:0;
	z-index:1000;
	display:none;
	float:left;
	min-width:10rem;
	padding:.5rem 0;
	margin:.125rem 0 0;
	font-size:.85rem;
	color:#858796;
	text-align:left;
	list-style:none;
	background-color:#fff;
	background-clip:padding-box;
	border:1px solid #e3e6f0;
	border-radius:.35rem 
}
.dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
	right:auto;
	bottom:auto 
}
.dropdown-divider {
	height:0;
	margin:.5rem 0;
	overflow:hidden;
	border-top:1px solid #eaecf4 
}
.dropdown-item {
	display:block;
	width:100%;
	padding:.25rem 1.5rem;
	clear:both;
	font-weight:400;
	color:#3a3b45;
	text-align:inherit;
	white-space:nowrap;
	background-color:transparent;
	border:0 
}
.dropdown-item:focus, .dropdown-item:hover {
	color:#2e2f37;
	text-decoration:none;
	background-color:#f8f9fc 
}
.dropdown-item.active, .dropdown-item:active {
	color:#fff;
	text-decoration:none;
	background-color:#4e73df 
}
.dropdown-item.disabled, .dropdown-item:disabled {
	color:#858796;
	pointer-events:none;
	background-color:transparent 
}
.dropdown-menu.show {
	display:block 
}
.dropdown-header {
	display:block;
	padding:.5rem 1.5rem;
	margin-bottom:0;
	font-size:.875rem;
	color:#858796;
	white-space:nowrap 
}
.dropdown-item-text {
	display:block;
	padding:.25rem 1.5rem;
	color:#3a3b45 
}
.dropdown-toggle-split {
	padding-right:.5625rem;
	padding-left:.5625rem 
}
.dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after {
	margin-left:0 
}
.dropleft .dropdown-toggle-split::before {
	margin-right:0 
}
.btn-group-sm>.btn+.dropdown-toggle-split, .btn-sm+.dropdown-toggle-split {
	padding-right:.375rem;
	padding-left:.375rem 
}
.btn-group-lg>.btn+.dropdown-toggle-split, .btn-lg+.dropdown-toggle-split {
	padding-right:.75rem;
	padding-left:.75rem 
}
.nav {
	display:flex;
	flex-wrap:wrap;
	padding-left:0;
	margin-bottom:0;
	list-style:none 
}
.nav-link {
	display:block;
	padding:.5rem 1rem 
}
.nav-link:focus, .nav-link:hover {
	text-decoration:none 
}
.nav-link.disabled {
	color:#858796;
	pointer-events:none;
	cursor:default 
}
.tab-content>.tab-pane {
	display:none 
}
.tab-content>.active {
	display:block 
}
.navbar {
	position:fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: #ffffff;
	border-bottom: 1px solid #e5e5e5;
	box-shadow: 0 .15rem 1.75rem 0 rgba(33,40,50,.15)!important;
	z-index: 1030;
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	justify-content:space-between;
	padding:.5rem 1rem 
}
.navbar>.container, .navbar>.container-fluid {
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	justify-content:space-between 
}
.navbar-brand {
	display:inline-block;
	padding-top:.3125rem;
	padding-bottom:.3125rem;
	margin-right:0.5rem;
	font-size:1.25rem;
	line-height:inherit;
	white-space:nowrap 
}
.navbar-brand:focus, .navbar-brand:hover {
	text-decoration:none 
}
.navbar-nav {
	display:flex;
	flex-direction:column;
	padding-left:0;
	margin-bottom:0;
	list-style:none 
}
.navbar-nav .nav-link {
	padding-right:0;
	padding-left:0 
}
.navbar-text {
	display:inline-block;
	padding-top:.5rem;
	padding-bottom:.5rem 
}
.navbar-collapse {
	flex-basis:100%;
	flex-grow:1;
	align-items:center 
}
.navbar-toggler {
	padding:.25rem .75rem;
	font-size:1.25rem;
	line-height:1;
	background-color:transparent;
	border:1px solid transparent;
	border-radius:.35rem 
}
.navbar-toggler:focus, .navbar-toggler:hover {
	text-decoration:none 
}
.navbar-toggler-icon {
	display:inline-block;
	width:1.5em;
	height:1.5em;
	vertical-align:middle;
	content:"";
	background:no-repeat center center;
	background-size:100% 100% 
}
@media (max-width:575.98px) {
	.navbar-expand-sm>.container, .navbar-expand-sm>.container-fluid {
		padding-right:0;
		padding-left:0 
   }
}
@media (min-width:576px) {
	.navbar-expand-sm {
		flex-flow:row nowrap;
		justify-content:flex-start 
   }
	.navbar-expand-sm .navbar-nav {
		flex-direction:row 
   }
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right:.5rem;
		padding-left:.5rem 
   }
	.navbar-expand-sm>.container, .navbar-expand-sm>.container-fluid {
		flex-wrap:nowrap 
   }
	.navbar-expand-sm .navbar-collapse {
		display:flex !important;
		flex-basis:auto 
   }
	.navbar-expand-sm .navbar-toggler {
		display:none 
   }
}
@media (max-width:767.98px) {
	.navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
		padding-right:0;
		padding-left:0 
   }
}
@media (min-width:768px) {
	.navbar-expand-md {
		flex-flow:row nowrap;
		justify-content:flex-start 
   }
	.navbar-expand-md .navbar-nav {
		flex-direction:row 
   }
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right:.5rem;
		padding-left:.5rem 
   }
	.navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
		flex-wrap:nowrap 
   }
	.navbar-expand-md .navbar-collapse {
		display:flex !important;
		flex-basis:auto 
   }
	.navbar-expand-md .navbar-toggler {
		display:none 
   }
}
@media (max-width:991.98px) {
	.navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
		padding-right:0;
		padding-left:0 
   }
}
@media (min-width:992px) {
	.navbar-expand-lg {
		flex-flow:row nowrap;
		justify-content:flex-start 
   }
	.navbar-expand-lg .navbar-nav {
		flex-direction:row 
   }
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right:.5rem;
		padding-left:.5rem 
   }
	.navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
		flex-wrap:nowrap 
   }
	.navbar-expand-lg .navbar-collapse {
		display:flex !important;
		flex-basis:auto 
   }
	.navbar-expand-lg .navbar-toggler {
		display:none 
   }
}
@media (max-width:1199.98px) {
	.navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
		padding-right:0;
		padding-left:0 
   }
}
@media (min-width:1200px) {
	.navbar-expand-xl {
		flex-flow:row nowrap;
		justify-content:flex-start 
   }
	.navbar-expand-xl .navbar-nav {
		flex-direction:row 
   }
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right:.5rem;
		padding-left:.5rem 
   }
	.navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
		flex-wrap:nowrap 
   }
	.navbar-expand-xl .navbar-collapse {
		display:flex !important;
		flex-basis:auto 
   }
	.navbar-expand-xl .navbar-toggler {
		display:none 
   }
}
.navbar-expand {
	flex-flow:row nowrap;
	justify-content:flex-start 
}
.navbar-expand>.container, .navbar-expand>.container-fluid {
	padding-right:0;
	padding-left:0 
}
.navbar-expand .navbar-nav {
	flex-direction:row 
}
.navbar-expand .navbar-nav .nav-link {
	padding-right:.5rem;
	padding-left:.5rem 
}
.navbar-expand>.container, .navbar-expand>.container-fluid {
	flex-wrap:nowrap 
}
.navbar-expand .navbar-collapse {
	display:flex !important;
	flex-basis:auto 
}
.navbar-expand .navbar-toggler {
	display:none 
}
.navbar-light .navbar-brand {
	color:rgba(0, 0, 0, .9) 
}
.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
	color:rgba(0, 0, 0, .9) 
}
.navbar-light .navbar-nav .nav-link {
	color:#212B36CC;
   font-weight:500;
	margin: 0px 5px;
	text-transform: capitalize;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
	color:#212B36 
}
.navbar-light .navbar-nav.active .nav-link {
	color:#212B36
}
.navbar-light .navbar-nav .nav-link.disabled {
	color:#212B364D 
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active {
	color:#212B36;
   font-weight:500;
}
.navbar-light .navbar-toggler {
	color:#212B3680;
	border-color:#212B361A 
}
.navbar-light .navbar-toggler-icon {
	background-image:url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") 
}
.navbar-light .navbar-text {
	color:rgba(0, 0, 0, .5) 
}
.navbar-light .navbar-text a {
	color:rgba(0, 0, 0, .9) 
}
.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
	color:rgba(0, 0, 0, .9) 
}
.card {
	position:relative;
	display:flex;
	flex-direction:column;
	min-width:0;
	word-wrap:break-word;
	background-color:#fff;
	background-clip:border-box;
	border:1px solid #e3e6f0;
	border-radius:.35rem 
}
.card>hr {
	margin-right:0;
	margin-left:0 
}
.card-body {
	flex:1 1 auto;
	padding:1rem 
}
.card-title {
	margin-bottom:.75rem 
}
.card-subtitle {
	margin-top:-.375rem;
	margin-bottom:0 
}
.card-text:last-child {
	margin-bottom:0 
}
.card-link:hover {
	text-decoration:none 
}
.card-link+.card-link {
	margin-left:1.25rem 
}
.card-header {
	padding:.5rem 1rem;
	margin-bottom:0;
	background-color:#f8f9fc;
	border-bottom:1px solid #e3e6f0 
}
.card-header:first-child {
	border-radius:calc(.35rem - 1px) calc(.35rem - 1px) 0 0 
}
.card-footer {
	padding:.75rem 1.25rem;
	background-color:#f8f9fc;
	border-top:1px solid #e3e6f0 
}
.card-footer:last-child {
	border-radius:0 0 calc(.35rem - 1px) calc(.35rem - 1px) 
}
.card-img-overlay {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	padding:1.25rem 
}
.card-img {
	width:100%;
	border-radius:calc(.35rem - 1px) 
}
.card-img-top {
	width:100%;
	border-top-left-radius:calc(.35rem - 1px);
	border-top-right-radius:calc(.35rem - 1px) 
}
.card-img-bottom {
	width:100%;
	border-bottom-right-radius:calc(.35rem - 1px);
	border-bottom-left-radius:calc(.35rem - 1px) 
}
.pagination {
	display:flex;
	padding-left:0;
	list-style:none;
	border-radius:.35rem;
	font-size: 14px;
}
.page-link {
	position:relative;
	display:block;
	padding:.5rem .75rem;
	cursor:pointer;
	margin-left:-1px;
	line-height:1.25;
	color:#4e73df;
	background-color:#fff;
	border:1px solid #dddfeb 
}
.page-link:hover {
	z-index:2;
	color:#224abe;
	text-decoration:none;
	background-color:#eaecf4;
	border-color:#dddfeb 
}
.page-link:focus {
	z-index:2;
	outline:0;
	box-shadow:0 0 0 .2rem rgba(78, 115, 223, .25) 
}
.page-item:first-child .page-link {
	margin-left:0;
	border-top-left-radius:.35rem;
	border-bottom-left-radius:.35rem 
}
.page-item:last-child .page-link {
	border-top-right-radius:.35rem;
	border-bottom-right-radius:.35rem 
}
.page-item.active .page-link {
	z-index:1;
	color:#fff;
	background-color:#4e73df;
	border-color:#4e73df 
}
.page-item.disabled .page-link {
	color:#858796;
	pointer-events:none;
	cursor:auto;
	background-color:#fff;
	border-color:#dddfeb 
}
.pagination-lg .page-link {
	padding:.75rem 1.5rem;
	font-size:1.25rem;
	line-height:1.5 
}
.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius:.3rem;
	border-bottom-left-radius:.3rem 
}
.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius:.3rem;
	border-bottom-right-radius:.3rem 
}
.pagination-sm .page-link {
	padding:.25rem .5rem;
	font-size:.875rem;
	line-height:1.5 
}
.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius:.2rem;
	border-bottom-left-radius:.2rem 
}
.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius:.2rem;
	border-bottom-right-radius:.2rem 
}
.badge {
	display:inline-block;
	padding:.25em .4em;
	font-size:75%;
	font-weight:700;
	line-height:1;
	text-align:center;
	white-space:nowrap;
	vertical-align:baseline;
	border-radius:.35rem;
	transition:color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out 
}
@media (prefers-reduced-motion:reduce) {
	.badge {
		transition:none 
   }
}
a.badge:focus, a.badge:hover {
	text-decoration:none 
}
.badge:empty {
	display:none 
}
.btn .badge {
	position:relative;
	top:-1px 
}
.badge-primary {
	color:#fff;
	background-color:#01315a 
}
a.badge-primary:focus, a.badge-primary:hover {
	color:#fff;
	background-color:#2653d4 
}
a.badge-primary.focus, a.badge-primary:focus {
	outline:0;
	box-shadow:0 0 0 .2rem rgba(78, 115, 223, .5) 
}
.badge-secondary {
	color:#fff;
	background-color:#fdb100 
}
a.badge-secondary:focus, a.badge-secondary:hover {
	color:#fff;
	background-color:#6b6d7d 
}
a.badge-secondary.focus, a.badge-secondary:focus {
	outline:0;
	box-shadow:0 0 0 .2rem rgba(133, 135, 150, .5) 
}
.badge-success {
	color:#fff;
	background-color:#058343 
}
a.badge-success:focus, a.badge-success:hover {
	color:#fff;
	background-color:#169b6b 
}
a.badge-success.focus, a.badge-success:focus {
	outline:0;
	box-shadow:0 0 0 .2rem rgba(28, 200, 138, .5) 
}
.badge-info {
	color:#fff;
	background-color:#06897c 
}
a.badge-info:focus, a.badge-info:hover {
	color:#fff;
	background-color:#2a96a5 
}
a.badge-info.focus, a.badge-info:focus {
	outline:0;
	box-shadow:0 0 0 .2rem rgba(54, 185, 204, .5) 
}
.badge-warning {
	color:#fff;
	background-color:#de760b 
}
a.badge-warning:focus, a.badge-warning:hover {
	color:#fff;
	background-color:#f4b30d 
}
a.badge-warning.focus, a.badge-warning:focus {
	outline:0;
	box-shadow:0 0 0 .2rem rgba(246, 194, 62, .5) 
}
.badge-danger {
	color:#fff;
	background-color:#ad1405 
}
a.badge-danger:focus, a.badge-danger:hover {
	color:#fff;
	background-color:#d52a1a 
}
a.badge-danger.focus, a.badge-danger:focus {
	outline:0;
	box-shadow:0 0 0 .2rem rgba(231, 74, 59, .5) 
}
.badge-light {
	color:#3a3b45;
	background-color:#f8f9fc 
}
a.badge-light:focus, a.badge-light:hover {
	color:#3a3b45;
	background-color:#d4daed 
}
a.badge-light.focus, a.badge-light:focus {
	outline:0;
	box-shadow:0 0 0 .2rem rgba(248, 249, 252, .5) 
}
.badge-dark {
	color:#fff;
	background-color:#5a5c69 
}
a.badge-dark:focus, a.badge-dark:hover {
	color:#fff;
	background-color:#42444e 
}
a.badge-dark.focus, a.badge-dark:focus {
	outline:0;
	box-shadow:0 0 0 .2rem rgba(90, 92, 105, .5) 
}
.close {
	float:right;
	font-size:1.5rem;
	font-weight:700;
	line-height:1;
	color:#000;
	text-shadow:0 1px 0 #fff;
	opacity:.5 
}
.close:hover {
	color:#000;
	text-decoration:none 
}
.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
	opacity:.75 
}
button.close {
	padding:0;
	background-color:transparent;
	border:0;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none 
}
a.close.disabled {
	pointer-events:none 
}
.carousel {
	position:relative 
}
.carousel.pointer-event {
	touch-action:pan-y 
}
.carousel-inner {
	position:relative;
	width:100%;
	overflow:hidden 
}
.carousel-inner::after {
	display:block;
	clear:both;
	content:"" 
}
.carousel-item {
	position:relative;
	display:none;
	float:left;
	width:100%;
	margin-right:-100%;
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden;
	transition:transform .6s ease-in-out 
}
@media (prefers-reduced-motion:reduce) {
	.carousel-item {
		transition:none 
   }
}
.carousel-item-next, .carousel-item-prev, .carousel-item.active {
	display:block 
}
.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
	transform:translateX(100%) 
}
.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
	transform:translateX(-100%) 
}
.carousel-fade .carousel-item {
	opacity:0;
	transition-property:opacity;
	transform:none 
}
.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
	z-index:1;
	opacity:1 
}
.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
	z-index:0;
	opacity:0;
	transition:0s .6s opacity 
}
@media (prefers-reduced-motion:reduce) {
	.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
		transition:none 
   }
}
.carousel-control-next, .carousel-control-prev {
	position:absolute;
	top:0;
	bottom:0;
	z-index:1;
	display:flex;
	align-items:center;
	justify-content:center;
	width:15%;
	color:#fff;
	text-align:center;
	opacity:.5;
	transition:opacity .15s ease 
}
@media (prefers-reduced-motion:reduce) {
	.carousel-control-next, .carousel-control-prev {
		transition:none 
   }
}
.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
	color:#fff;
	text-decoration:none;
	outline:0;
	opacity:.9 
}
.carousel-control-prev {
	left:0 
}
.carousel-control-next {
	right:0 
}
.carousel-control-next-icon, .carousel-control-prev-icon {
	display:inline-block;
	width:20px;
	height:20px;
	background:no-repeat 50%/100% 100% 
}
.carousel-control-prev-icon {
	background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e") 
}
.carousel-control-next-icon {
	background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e") 
}
.carousel-indicators {
	position:absolute;
	right:0;
	bottom:0;
	left:0;
	z-index:15;
	display:flex;
	justify-content:center;
	padding-left:0;
	margin-right:15%;
	margin-left:15%;
	list-style:none 
}
.carousel-indicators li {
	box-sizing:content-box;
	flex:0 1 auto;
	width:30px;
	height:3px;
	margin-right:3px;
	margin-left:3px;
	text-indent:-999px;
	cursor:pointer;
	background-color:#fff;
	background-clip:padding-box;
	border-top:10px solid transparent;
	border-bottom:10px solid transparent;
	opacity:.5;
	transition:opacity .6s ease 
}
@media (prefers-reduced-motion:reduce) {
	.carousel-indicators li {
		transition:none 
   }
}
.carousel-indicators .active {
	opacity:1 
}
.carousel-caption {
	position:absolute;
	right:15%;
	bottom:20px;
	left:15%;
	z-index:10;
	padding-top:20px;
	padding-bottom:20px;
	color:#fff;
	text-align:center 
}
@-webkit-keyframes spinner-border {
	to {
		transform:rotate(360deg) 
   }
}
@keyframes spinner-border {
	to {
		transform:rotate(360deg) 
   }
}
.spinner-border {
	display:inline-block;
	width:2rem;
	height:2rem;
	vertical-align:text-bottom;
	border:.25em solid currentColor;
	border-right-color:transparent;
	border-radius:50%;
	-webkit-animation:spinner-border .75s linear infinite;
	animation:spinner-border .75s linear infinite 
}
.spinner-border-sm {
	width:1rem;
	height:1rem;
	border-width:.2em 
}
@-webkit-keyframes spinner-grow {
	0% {
		transform:scale(0) 
   }
	50% {
		opacity:1 
   }
}
@keyframes spinner-grow {
	0% {
		transform:scale(0) 
   }
	50% {
		opacity:1 
   }
}
.spinner-grow {
	display:inline-block;
	width:2rem;
	height:2rem;
	vertical-align:text-bottom;
	background-color:currentColor;
	border-radius:50%;
	opacity:0;
	-webkit-animation:spinner-grow .75s linear infinite;
	animation:spinner-grow .75s linear infinite 
}
.spinner-grow-sm {
	width:1rem;
	height:1rem 
}
.align-baseline {
	vertical-align:baseline !important 
}
.align-top {
	vertical-align:top !important 
}
.align-middle {
	vertical-align:middle !important 
}
.align-bottom {
	vertical-align:bottom !important 
}
.align-text-bottom {
	vertical-align:text-bottom !important 
}
.align-text-top {
	vertical-align:text-top !important 
}
.bg-primary {
	background-color:#4e73df !important 
}
a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
	background-color:#2653d4 !important 
}
.bg-secondary {
	background-color:#858796 !important 
}
a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
	background-color:#6b6d7d !important 
}
.bg-success {
	background-color:#058343 !important 
}
a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
	background-color:#169b6b !important 
}
.bg-info {
	background-color:#36b9cc !important 
}
a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
	background-color:#2a96a5 !important 
}
.bg-warning {
	background-color:#dea20b !important 
}
a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
	background-color:#f4b30d !important 
}
.bg-danger {
	background-color:#e74a3b !important 
}
a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
	background-color:#d52a1a !important 
}
.bg-light {
	background-color:#f8f9fc !important 
}
a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
	background-color:#d4daed !important 
}
.bg-dark {
	background-color:#5a5c69 !important 
}
a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
	background-color:#42444e !important 
}
.bg-white {
	background-color:#fff !important 
}
.bg-transparent {
	background-color:transparent !important 
}
.border {
	border:1px solid #e3e6f0 !important 
}
.border-top {
	border-top:1px solid #e3e6f0 !important 
}
.border-right {
	border-right:1px solid #c5cbd1 !important 
}
.border-bottom {
	border-bottom:1px solid #e3e6f0 !important 
}
.border-left {
	border-left:1px solid #e3e6f0 !important 
}
.border-0 {
	border:0 !important 
}
.border-top-0 {
	border-top:0 !important 
}
.border-right-0 {
	border-right:0 !important 
}
.border-bottom-0 {
	border-bottom:0 !important 
}
.border-left-0 {
	border-left:0 !important 
}
.clearfix::after {
	display:block;
	clear:both;
	content:"" 
}
.d-none {
	display:none !important 
}
.d-inline {
	display:inline !important 
}
.d-inline-block {
	display:inline-block !important 
}
.d-block {
	display:block !important 
}
.d-flex {
	display:flex !important 
}
@media (min-width:576px) {
	.d-sm-none {
		display:none !important 
   }
	.d-sm-inline {
		display:inline !important 
   }
	.d-sm-inline-block {
		display:inline-block !important 
   }
	.d-sm-block {
		display:block !important 
   }
	.d-sm-flex {
		display:flex !important 
   }
}
@media (min-width:768px) {
	.d-md-none {
		display:none !important 
   }
	.d-md-inline {
		display:inline !important 
   }
	.d-md-inline-block {
		display:inline-block !important 
   }
	.d-md-block {
		display:block !important 
   }
	.d-md-flex {
		display:flex !important 
   }
}
@media (min-width:992px) {
	.d-lg-none {
		display:none !important 
   }
	.d-lg-inline {
		display:inline !important 
   }
	.d-lg-inline-block {
		display:inline-block !important 
   }
	.d-lg-block {
		display:block !important 
   }
	.d-lg-flex {
		display:flex !important 
   }
}
@media (min-width:1200px) {
	.d-xl-none {
		display:none !important 
   }
	.d-xl-inline {
		display:inline !important 
   }
	.d-xl-inline-block {
		display:inline-block !important 
   }
	.d-xl-block {
		display:block !important 
   }
	.d-xl-flex {
		display:flex !important 
   }
}
.flex-row {
	flex-direction:row !important 
}
.flex-column {
	flex-direction:column !important 
}
.flex-wrap {
	flex-wrap:wrap !important 
}
.flex-nowrap {
	flex-wrap:nowrap !important 
}
.flex-fill {
	flex:1 1 auto !important 
}
.flex-grow-0 {
	flex-grow:0 !important 
}
.flex-grow-1 {
	flex-grow:1 !important 
}
.flex-shrink-0 {
	flex-shrink:0 !important 
}
.flex-shrink-1 {
	flex-shrink:1 !important 
}
.justify-content-start {
	justify-content:flex-start !important 
}
.justify-content-end {
	justify-content:flex-end !important 
}
.justify-content-center {
	justify-content:center !important 
}
.justify-content-between {
	justify-content:space-between !important 
}
.justify-content-around {
	justify-content:space-around !important 
}
.align-items-start {
	align-items:flex-start !important 
}
.align-items-end {
	align-items:flex-end !important 
}
.align-items-center {
	align-items:center !important 
}
.align-items-baseline {
	align-items:baseline !important 
}
.align-items-stretch {
	align-items:stretch !important 
}
@media (min-width:576px) {
	.flex-sm-row {
		flex-direction:row !important 
   }
	.flex-sm-column {
		flex-direction:column !important 
   }
	.flex-sm-wrap {
		flex-wrap:wrap !important 
   }
	.flex-sm-nowrap {
		flex-wrap:nowrap !important 
   }
	.flex-sm-fill {
		flex:1 1 auto !important 
   }
	.flex-sm-grow-0 {
		flex-grow:0 !important 
   }
	.flex-sm-grow-1 {
		flex-grow:1 !important 
   }
	.flex-sm-shrink-0 {
		flex-shrink:0 !important 
   }
	.flex-sm-shrink-1 {
		flex-shrink:1 !important 
   }
	.justify-content-sm-start {
		justify-content:flex-start !important 
   }
	.justify-content-sm-end {
		justify-content:flex-end !important 
   }
	.justify-content-sm-center {
		justify-content:center !important 
   }
	.justify-content-sm-between {
		justify-content:space-between !important 
   }
	.justify-content-sm-around {
		justify-content:space-around !important 
   }
	.align-items-sm-start {
		align-items:flex-start !important 
   }
	.align-items-sm-end {
		align-items:flex-end !important 
   }
	.align-items-sm-center {
		align-items:center !important 
   }
	.align-items-sm-baseline {
		align-items:baseline !important 
   }
	.align-items-sm-stretch {
		align-items:stretch !important 
   }
}
@media (min-width:768px) {
	.flex-md-row {
		flex-direction:row !important 
   }
	.flex-md-column {
		flex-direction:column !important 
   }
	.flex-md-wrap {
		flex-wrap:wrap !important 
   }
	.flex-md-nowrap {
		flex-wrap:nowrap !important 
   }
	.flex-md-fill {
		flex:1 1 auto !important 
   }
	.flex-md-grow-0 {
		flex-grow:0 !important 
   }
	.flex-md-grow-1 {
		flex-grow:1 !important 
   }
	.flex-md-shrink-0 {
		flex-shrink:0 !important 
   }
	.flex-md-shrink-1 {
		flex-shrink:1 !important 
   }
	.justify-content-md-start {
		justify-content:flex-start !important 
   }
	.justify-content-md-end {
		justify-content:flex-end !important 
   }
	.justify-content-md-center {
		justify-content:center !important 
   }
	.justify-content-md-between {
		justify-content:space-between !important 
   }
	.justify-content-md-around {
		justify-content:space-around !important 
   }
	.align-items-md-start {
		align-items:flex-start !important 
   }
	.align-items-md-end {
		align-items:flex-end !important 
   }
	.align-items-md-center {
		align-items:center !important 
   }
	.align-items-md-baseline {
		align-items:baseline !important 
   }
	.align-items-md-stretch {
		align-items:stretch !important 
   }
}
@media (min-width:992px) {
	.flex-lg-row {
		flex-direction:row !important 
   }
	.flex-lg-column {
		flex-direction:column !important 
   }
	.flex-lg-wrap {
		flex-wrap:wrap !important 
   }
	.flex-lg-nowrap {
		flex-wrap:nowrap !important 
   }
	.flex-lg-fill {
		flex:1 1 auto !important 
   }
	.flex-lg-grow-0 {
		flex-grow:0 !important 
   }
	.flex-lg-grow-1 {
		flex-grow:1 !important 
   }
	.flex-lg-shrink-0 {
		flex-shrink:0 !important 
   }
	.flex-lg-shrink-1 {
		flex-shrink:1 !important 
   }
	.justify-content-lg-start {
		justify-content:flex-start !important 
   }
	.justify-content-lg-end {
		justify-content:flex-end !important 
   }
	.justify-content-lg-center {
		justify-content:center !important 
   }
	.justify-content-lg-between {
		justify-content:space-between !important 
   }
	.justify-content-lg-around {
		justify-content:space-around !important 
   }
	.align-items-lg-start {
		align-items:flex-start !important 
   }
	.align-items-lg-end {
		align-items:flex-end !important 
   }
	.align-items-lg-center {
		align-items:center !important 
   }
	.align-items-lg-baseline {
		align-items:baseline !important 
   }
	.align-items-lg-stretch {
		align-items:stretch !important 
   }
}
@media (min-width:1200px) {
	.flex-xl-row {
		flex-direction:row !important 
   }
	.flex-xl-column {
		flex-direction:column !important 
   }
	.flex-xl-wrap {
		flex-wrap:wrap !important 
   }
	.flex-xl-nowrap {
		flex-wrap:nowrap !important 
   }
	.flex-xl-fill {
		flex:1 1 auto !important 
   }
	.flex-xl-grow-0 {
		flex-grow:0 !important 
   }
	.flex-xl-grow-1 {
		flex-grow:1 !important 
   }
	.flex-xl-shrink-0 {
		flex-shrink:0 !important 
   }
	.flex-xl-shrink-1 {
		flex-shrink:1 !important 
   }
	.justify-content-xl-start {
		justify-content:flex-start !important 
   }
	.justify-content-xl-end {
		justify-content:flex-end !important 
   }
	.justify-content-xl-center {
		justify-content:center !important 
   }
	.justify-content-xl-between {
		justify-content:space-between !important 
   }
	.justify-content-xl-around {
		justify-content:space-around !important 
   }
	.align-items-xl-start {
		align-items:flex-start !important 
   }
	.align-items-xl-end {
		align-items:flex-end !important 
   }
	.align-items-xl-center {
		align-items:center !important 
   }
	.align-items-xl-baseline {
		align-items:baseline !important 
   }
	.align-items-xl-stretch {
		align-items:stretch !important 
   }
}
.overflow-auto {
	overflow:auto !important 
}
.overflow-hidden {
	overflow:hidden !important 
}
.position-static {
	position:static !important 
}
.position-relative {
	position:relative !important 
}
.position-absolute {
	position:absolute !important 
}
.position-fixed {
	position:fixed !important 
}
.position-sticky {
	position:-webkit-sticky !important;
	position:sticky !important 
}
.fixed-top {
	position:fixed;
	top:0;
	right:0;
	left:0;
	z-index:1030 
}
.fixed-bottom {
	position:fixed;
	right:0;
	bottom:0;
	left:0;
	z-index:1030 
}
@supports ((position:-webkit-sticky) or (position:sticky)) {
	.sticky-top {
		position:-webkit-sticky;
		position:sticky;
		top:0;
		z-index:1020 
   }
}
.sr-only {
	position:absolute;
	width:1px;
	height:1px;
	padding:0;
	overflow:hidden;
	clip:rect(0, 0, 0, 0);
	white-space:nowrap;
	border:0 
}
.sr-only-focusable:active, .sr-only-focusable:focus {
	position:static;
	width:auto;
	height:auto;
	overflow:visible;
	clip:auto;
	white-space:normal 
}
.shadow-sm {
	box-shadow:0 .125rem .25rem 0 rgba(58, 59, 69,.2) !important 
}
.shadow {
	box-shadow:0 .15rem 1.75rem 0 rgba(58, 59, 69,.15) !important 
}
.shadow-lg {
	box-shadow:0 1rem 3rem rgba(0, 0, 0,.175) !important 
}
.shadow-none {
	box-shadow:none !important 
}
.w-100 {
	width:100% !important 
}
.h-100 {
	height:100% !important 
}
.stretched-link::after {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1;
	pointer-events:auto;
	content:"";
	background-color:rgba(0, 0, 0, 0) 
}
.m-0 {
	margin:0 !important 
}
.mt-0, .my-0 {
	margin-top:0 !important 
}
.mr-0, .mx-0 {
	margin-right:0 !important 
}
.mb-0, .my-0 {
	margin-bottom:0 !important 
}
.ml-0, .mx-0 {
	margin-left:0 !important 
}
.m-1 {
	margin:.25rem !important 
}
.mt-1, .my-1 {
	margin-top:.25rem !important 
}
.mr-1, .mx-1 {
	margin-right:.25rem !important 
}
.mb-1, .my-1 {
	margin-bottom:.25rem !important 
}
.ml-1, .mx-1 {
	margin-left:.25rem !important 
}
.m-2 {
	margin:.5rem !important 
}
.mt-2, .my-2 {
	margin-top:.5rem !important 
}
.mr-2, .mx-2 {
	margin-right:.5rem !important 
}
.mb-2, .my-2 {
	margin-bottom:.5rem !important 
}
.ml-2, .mx-2 {
	margin-left:.5rem !important 
}
.m-3 {
	margin:1rem !important 
}
.mt-3, .my-3 {
	margin-top:1rem !important 
}
.mr-3, .mx-3 {
	margin-right:1rem !important 
}
.mb-3, .my-3 {
	margin-bottom:1rem !important 
}
.ml-3, .mx-3 {
	margin-left:1rem !important 
}
.m-4 {
	margin:1.5rem !important 
}
.mt-4, .my-4 {
	margin-top:1.5rem !important 
}
.mr-4, .mx-4 {
	margin-right:1.5rem !important 
}
.mb-4, .my-4 {
	margin-bottom:1.5rem !important 
}
.ml-4, .mx-4 {
	margin-left:1.5rem !important 
}
.m-5 {
	margin:3rem !important 
}
.mt-5, .my-5 {
	margin-top:3rem !important 
}
.mr-5, .mx-5 {
	margin-right:3rem !important 
}
.mb-5, .my-5 {
	margin-bottom:3rem !important 
}
.ml-5, .mx-5 {
	margin-left:3rem !important 
}
.p-0 {
	padding:0 !important 
}
.pt-0, .py-0 {
	padding-top:0 !important 
}
.pr-0, .px-0 {
	padding-right:0 !important 
}
.pb-0, .py-0 {
	padding-bottom:0 !important 
}
.pl-0, .px-0 {
	padding-left:0 !important 
}
.p-1 {
	padding:.25rem !important 
}
.pt-1, .py-1 {
	padding-top:.25rem !important 
}
.pr-1, .px-1 {
	padding-right:.25rem !important 
}
.pb-1, .py-1 {
	padding-bottom:.25rem !important 
}
.pl-1, .px-1 {
	padding-left:.25rem !important 
}
.p-2 {
	padding:.5rem !important 
}
.pt-2, .py-2 {
	padding-top:.5rem !important 
}
.pr-2, .px-2 {
	padding-right:.5rem !important 
}
.pb-2, .py-2 {
	padding-bottom:.5rem !important 
}
.pl-2, .px-2 {
	padding-left:.5rem !important 
}
.p-3 {
	padding:1rem !important 
}
.pt-3, .py-3 {
	padding-top:1rem !important 
}
.pr-3, .px-3 {
	padding-right:1rem !important 
}
.pb-3, .py-3 {
	padding-bottom:1rem !important 
}
.pl-3, .px-3 {
	padding-left:1rem !important 
}
.p-4 {
	padding:1.5rem !important 
}
.pt-4, .py-4 {
	padding-top:1.5rem !important 
}
.pr-4, .px-4 {
	padding-right:1.5rem !important 
}
.pb-4, .py-4 {
	padding-bottom:1.5rem !important 
}
.pl-4, .px-4 {
	padding-left:1.5rem !important 
}
.p-5 {
	padding:3rem !important 
}
.pt-5, .py-5 {
	padding-top:3rem !important 
}
.pr-5, .px-5 {
	padding-right:3rem !important 
}
.pb-5, .py-5 {
	padding-bottom:3rem !important 
}
.pl-5, .px-5 {
	padding-left:3rem !important 
}
.m-n1 {
	margin:-.25rem !important 
}
.mt-n1, .my-n1 {
	margin-top:-.25rem !important 
}
.mr-n1, .mx-n1 {
	margin-right:-.25rem !important 
}
.mb-n1, .my-n1 {
	margin-bottom:-.25rem !important 
}
.ml-n1, .mx-n1 {
	margin-left:-.25rem !important 
}
.m-n2 {
	margin:-.5rem !important 
}
.mt-n2, .my-n2 {
	margin-top:-.5rem !important 
}
.mr-n2, .mx-n2 {
	margin-right:-.5rem !important 
}
.mb-n2, .my-n2 {
	margin-bottom:-.5rem !important 
}
.ml-n2, .mx-n2 {
	margin-left:-.5rem !important 
}
.m-n3 {
	margin:-1rem !important 
}
.mt-n3, .my-n3 {
	margin-top:-1rem !important 
}
.mr-n3, .mx-n3 {
	margin-right:-1rem !important 
}
.mb-n3, .my-n3 {
	margin-bottom:-1rem !important 
}
.ml-n3, .mx-n3 {
	margin-left:-1rem !important 
}
.m-n4 {
	margin:-1.5rem !important 
}
.mt-n4, .my-n4 {
	margin-top:-1.5rem !important 
}
.mr-n4, .mx-n4 {
	margin-right:-1.5rem !important 
}
.mb-n4, .my-n4 {
	margin-bottom:-1.5rem !important 
}
.ml-n4, .mx-n4 {
	margin-left:-1.5rem !important 
}
.m-n5 {
	margin:-3rem !important 
}
.mt-n5, .my-n5 {
	margin-top:-3rem !important 
}
.mr-n5, .mx-n5 {
	margin-right:-3rem !important 
}
.mb-n5, .my-n5 {
	margin-bottom:-3rem !important 
}
.ml-n5, .mx-n5 {
	margin-left:-3rem !important 
}
.m-auto {
	margin:auto !important 
}
.mt-auto, .my-auto {
	margin-top:auto !important 
}
.mr-auto, .mx-auto {
	margin-right:auto !important 
}
.mb-auto, .my-auto {
	margin-bottom:auto !important 
}
.ml-auto, .mx-auto {
	margin-left:auto !important 
}
@media (min-width:576px) {
	.m-sm-0 {
		margin:0 !important 
   }
	.mt-sm-0, .my-sm-0 {
		margin-top:0 !important 
   }
	.mr-sm-0, .mx-sm-0 {
		margin-right:0 !important 
   }
	.mb-sm-0, .my-sm-0 {
		margin-bottom:0 !important 
   }
	.ml-sm-0, .mx-sm-0 {
		margin-left:0 !important 
   }
	.m-sm-1 {
		margin:.25rem !important 
   }
	.mt-sm-1, .my-sm-1 {
		margin-top:.25rem !important 
   }
	.mr-sm-1, .mx-sm-1 {
		margin-right:.25rem !important 
   }
	.mb-sm-1, .my-sm-1 {
		margin-bottom:.25rem !important 
   }
	.ml-sm-1, .mx-sm-1 {
		margin-left:.25rem !important 
   }
	.m-sm-2 {
		margin:.5rem !important 
   }
	.mt-sm-2, .my-sm-2 {
		margin-top:.5rem !important 
   }
	.mr-sm-2, .mx-sm-2 {
		margin-right:.5rem !important 
   }
	.mb-sm-2, .my-sm-2 {
		margin-bottom:.5rem !important 
   }
	.ml-sm-2, .mx-sm-2 {
		margin-left:.5rem !important 
   }
	.m-sm-3 {
		margin:1rem !important 
   }
	.mt-sm-3, .my-sm-3 {
		margin-top:1rem !important 
   }
	.mr-sm-3, .mx-sm-3 {
		margin-right:1rem !important 
   }
	.mb-sm-3, .my-sm-3 {
		margin-bottom:1rem !important 
   }
	.ml-sm-3, .mx-sm-3 {
		margin-left:1rem !important 
   }
	.m-sm-4 {
		margin:1.5rem !important 
   }
	.mt-sm-4, .my-sm-4 {
		margin-top:1.5rem !important 
   }
	.mr-sm-4, .mx-sm-4 {
		margin-right:1.5rem !important 
   }
	.mb-sm-4, .my-sm-4 {
		margin-bottom:1.5rem !important 
   }
	.ml-sm-4, .mx-sm-4 {
		margin-left:1.5rem !important 
   }
	.m-sm-5 {
		margin:3rem !important 
   }
	.mt-sm-5, .my-sm-5 {
		margin-top:3rem !important 
   }
	.mr-sm-5, .mx-sm-5 {
		margin-right:3rem !important 
   }
	.mb-sm-5, .my-sm-5 {
		margin-bottom:3rem !important 
   }
	.ml-sm-5, .mx-sm-5 {
		margin-left:3rem !important 
   }
	.p-sm-0 {
		padding:0 !important 
   }
	.pt-sm-0, .py-sm-0 {
		padding-top:0 !important 
   }
	.pr-sm-0, .px-sm-0 {
		padding-right:0 !important 
   }
	.pb-sm-0, .py-sm-0 {
		padding-bottom:0 !important 
   }
	.pl-sm-0, .px-sm-0 {
		padding-left:0 !important 
   }
	.p-sm-1 {
		padding:.25rem !important 
   }
	.pt-sm-1, .py-sm-1 {
		padding-top:.25rem !important 
   }
	.pr-sm-1, .px-sm-1 {
		padding-right:.25rem !important 
   }
	.pb-sm-1, .py-sm-1 {
		padding-bottom:.25rem !important 
   }
	.pl-sm-1, .px-sm-1 {
		padding-left:.25rem !important 
   }
	.p-sm-2 {
		padding:.5rem !important 
   }
	.pt-sm-2, .py-sm-2 {
		padding-top:.5rem !important 
   }
	.pr-sm-2, .px-sm-2 {
		padding-right:.5rem !important 
   }
	.pb-sm-2, .py-sm-2 {
		padding-bottom:.5rem !important 
   }
	.pl-sm-2, .px-sm-2 {
		padding-left:.5rem !important 
   }
	.p-sm-3 {
		padding:1rem !important 
   }
	.pt-sm-3, .py-sm-3 {
		padding-top:1rem !important 
   }
	.pr-sm-3, .px-sm-3 {
		padding-right:1rem !important 
   }
	.pb-sm-3, .py-sm-3 {
		padding-bottom:1rem !important 
   }
	.pl-sm-3, .px-sm-3 {
		padding-left:1rem !important 
   }
	.p-sm-4 {
		padding:1.5rem !important 
   }
	.pt-sm-4, .py-sm-4 {
		padding-top:1.5rem !important 
   }
	.pr-sm-4, .px-sm-4 {
		padding-right:1.5rem !important 
   }
	.pb-sm-4, .py-sm-4 {
		padding-bottom:1.5rem !important 
   }
	.pl-sm-4, .px-sm-4 {
		padding-left:1.5rem !important 
   }
	.p-sm-5 {
		padding:3rem !important 
   }
	.pt-sm-5, .py-sm-5 {
		padding-top:3rem !important 
   }
	.pr-sm-5, .px-sm-5 {
		padding-right:3rem !important 
   }
	.pb-sm-5, .py-sm-5 {
		padding-bottom:3rem !important 
   }
	.pl-sm-5, .px-sm-5 {
		padding-left:3rem !important 
   }
	.m-sm-n1 {
		margin:-.25rem !important 
   }
	.mt-sm-n1, .my-sm-n1 {
		margin-top:-.25rem !important 
   }
	.mr-sm-n1, .mx-sm-n1 {
		margin-right:-.25rem !important 
   }
	.mb-sm-n1, .my-sm-n1 {
		margin-bottom:-.25rem !important 
   }
	.ml-sm-n1, .mx-sm-n1 {
		margin-left:-.25rem !important 
   }
	.m-sm-n2 {
		margin:-.5rem !important 
   }
	.mt-sm-n2, .my-sm-n2 {
		margin-top:-.5rem !important 
   }
	.mr-sm-n2, .mx-sm-n2 {
		margin-right:-.5rem !important 
   }
	.mb-sm-n2, .my-sm-n2 {
		margin-bottom:-.5rem !important 
   }
	.ml-sm-n2, .mx-sm-n2 {
		margin-left:-.5rem !important 
   }
	.m-sm-n3 {
		margin:-1rem !important 
   }
	.mt-sm-n3, .my-sm-n3 {
		margin-top:-1rem !important 
   }
	.mr-sm-n3, .mx-sm-n3 {
		margin-right:-1rem !important 
   }
	.mb-sm-n3, .my-sm-n3 {
		margin-bottom:-1rem !important 
   }
	.ml-sm-n3, .mx-sm-n3 {
		margin-left:-1rem !important 
   }
	.m-sm-n4 {
		margin:-1.5rem !important 
   }
	.mt-sm-n4, .my-sm-n4 {
		margin-top:-1.5rem !important 
   }
	.mr-sm-n4, .mx-sm-n4 {
		margin-right:-1.5rem !important 
   }
	.mb-sm-n4, .my-sm-n4 {
		margin-bottom:-1.5rem !important 
   }
	.ml-sm-n4, .mx-sm-n4 {
		margin-left:-1.5rem !important 
   }
	.m-sm-n5 {
		margin:-3rem !important 
   }
	.mt-sm-n5, .my-sm-n5 {
		margin-top:-3rem !important 
   }
	.mr-sm-n5, .mx-sm-n5 {
		margin-right:-3rem !important 
   }
	.mb-sm-n5, .my-sm-n5 {
		margin-bottom:-3rem !important 
   }
	.ml-sm-n5, .mx-sm-n5 {
		margin-left:-3rem !important 
   }
	.m-sm-auto {
		margin:auto !important 
   }
	.mt-sm-auto, .my-sm-auto {
		margin-top:auto !important 
   }
	.mr-sm-auto, .mx-sm-auto {
		margin-right:auto !important 
   }
	.mb-sm-auto, .my-sm-auto {
		margin-bottom:auto !important 
   }
	.ml-sm-auto, .mx-sm-auto {
		margin-left:auto !important 
   }
}
@media (min-width:768px) {
	.m-md-0 {
		margin:0 !important 
   }
	.mt-md-0, .my-md-0 {
		margin-top:0 !important 
   }
	.mr-md-0, .mx-md-0 {
		margin-right:0 !important 
   }
	.mb-md-0, .my-md-0 {
		margin-bottom:0 !important 
   }
	.ml-md-0, .mx-md-0 {
		margin-left:0 !important 
   }
	.m-md-1 {
		margin:.25rem !important 
   }
	.mt-md-1, .my-md-1 {
		margin-top:.25rem !important 
   }
	.mr-md-1, .mx-md-1 {
		margin-right:.25rem !important 
   }
	.mb-md-1, .my-md-1 {
		margin-bottom:.25rem !important 
   }
	.ml-md-1, .mx-md-1 {
		margin-left:.25rem !important 
   }
	.m-md-2 {
		margin:.5rem !important 
   }
	.mt-md-2, .my-md-2 {
		margin-top:.5rem !important 
   }
	.mr-md-2, .mx-md-2 {
		margin-right:.5rem !important 
   }
	.mb-md-2, .my-md-2 {
		margin-bottom:.5rem !important 
   }
	.ml-md-2, .mx-md-2 {
		margin-left:.5rem !important 
   }
	.m-md-3 {
		margin:1rem !important 
   }
	.mt-md-3, .my-md-3 {
		margin-top:1rem !important 
   }
	.mr-md-3, .mx-md-3 {
		margin-right:1rem !important 
   }
	.mb-md-3, .my-md-3 {
		margin-bottom:1rem !important 
   }
	.ml-md-3, .mx-md-3 {
		margin-left:1rem !important 
   }
	.m-md-4 {
		margin:1.5rem !important 
   }
	.mt-md-4, .my-md-4 {
		margin-top:1.5rem !important 
   }
	.mr-md-4, .mx-md-4 {
		margin-right:1.5rem !important 
   }
	.mb-md-4, .my-md-4 {
		margin-bottom:1.5rem !important 
   }
	.ml-md-4, .mx-md-4 {
		margin-left:1.5rem !important 
   }
	.m-md-5 {
		margin:3rem !important 
   }
	.mt-md-5, .my-md-5 {
		margin-top:3rem !important 
   }
	.mr-md-5, .mx-md-5 {
		margin-right:3rem !important 
   }
	.mb-md-5, .my-md-5 {
		margin-bottom:3rem !important 
   }
	.ml-md-5, .mx-md-5 {
		margin-left:3rem !important 
   }
	.p-md-0 {
		padding:0 !important 
   }
	.pt-md-0, .py-md-0 {
		padding-top:0 !important 
   }
	.pr-md-0, .px-md-0 {
		padding-right:0 !important 
   }
	.pb-md-0, .py-md-0 {
		padding-bottom:0 !important 
   }
	.pl-md-0, .px-md-0 {
		padding-left:0 !important 
   }
	.p-md-1 {
		padding:.25rem !important 
   }
	.pt-md-1, .py-md-1 {
		padding-top:.25rem !important 
   }
	.pr-md-1, .px-md-1 {
		padding-right:.25rem !important 
   }
	.pb-md-1, .py-md-1 {
		padding-bottom:.25rem !important 
   }
	.pl-md-1, .px-md-1 {
		padding-left:.25rem !important 
   }
	.p-md-2 {
		padding:.5rem !important 
   }
	.pt-md-2, .py-md-2 {
		padding-top:.5rem !important 
   }
	.pr-md-2, .px-md-2 {
		padding-right:.5rem !important 
   }
	.pb-md-2, .py-md-2 {
		padding-bottom:.5rem !important 
   }
	.pl-md-2, .px-md-2 {
		padding-left:.5rem !important 
   }
	.p-md-3 {
		padding:1rem !important 
   }
	.pt-md-3, .py-md-3 {
		padding-top:1rem !important 
   }
	.pr-md-3, .px-md-3 {
		padding-right:1rem !important 
   }
	.pb-md-3, .py-md-3 {
		padding-bottom:1rem !important 
   }
	.pl-md-3, .px-md-3 {
		padding-left:1rem !important 
   }
	.p-md-4 {
		padding:1.5rem !important 
   }
	.pt-md-4, .py-md-4 {
		padding-top:1.5rem !important 
   }
	.pr-md-4, .px-md-4 {
		padding-right:1.5rem !important 
   }
	.pb-md-4, .py-md-4 {
		padding-bottom:1.5rem !important 
   }
	.pl-md-4, .px-md-4 {
		padding-left:1.5rem !important 
   }
	.p-md-5 {
		padding:3rem !important 
   }
	.pt-md-5, .py-md-5 {
		padding-top:3rem !important 
   }
	.pr-md-5, .px-md-5 {
		padding-right:3rem !important 
   }
	.pb-md-5, .py-md-5 {
		padding-bottom:3rem !important 
   }
	.pl-md-5, .px-md-5 {
		padding-left:3rem !important 
   }
	.m-md-n1 {
		margin:-.25rem !important 
   }
	.mt-md-n1, .my-md-n1 {
		margin-top:-.25rem !important 
   }
	.mr-md-n1, .mx-md-n1 {
		margin-right:-.25rem !important 
   }
	.mb-md-n1, .my-md-n1 {
		margin-bottom:-.25rem !important 
   }
	.ml-md-n1, .mx-md-n1 {
		margin-left:-.25rem !important 
   }
	.m-md-n2 {
		margin:-.5rem !important 
   }
	.mt-md-n2, .my-md-n2 {
		margin-top:-.5rem !important 
   }
	.mr-md-n2, .mx-md-n2 {
		margin-right:-.5rem !important 
   }
	.mb-md-n2, .my-md-n2 {
		margin-bottom:-.5rem !important 
   }
	.ml-md-n2, .mx-md-n2 {
		margin-left:-.5rem !important 
   }
	.m-md-n3 {
		margin:-1rem !important 
   }
	.mt-md-n3, .my-md-n3 {
		margin-top:-1rem !important 
   }
	.mr-md-n3, .mx-md-n3 {
		margin-right:-1rem !important 
   }
	.mb-md-n3, .my-md-n3 {
		margin-bottom:-1rem !important 
   }
	.ml-md-n3, .mx-md-n3 {
		margin-left:-1rem !important 
   }
	.m-md-n4 {
		margin:-1.5rem !important 
   }
	.mt-md-n4, .my-md-n4 {
		margin-top:-1.5rem !important 
   }
	.mr-md-n4, .mx-md-n4 {
		margin-right:-1.5rem !important 
   }
	.mb-md-n4, .my-md-n4 {
		margin-bottom:-1.5rem !important 
   }
	.ml-md-n4, .mx-md-n4 {
		margin-left:-1.5rem !important 
   }
	.m-md-n5 {
		margin:-3rem !important 
   }
	.mt-md-n5, .my-md-n5 {
		margin-top:-3rem !important 
   }
	.mr-md-n5, .mx-md-n5 {
		margin-right:-3rem !important 
   }
	.mb-md-n5, .my-md-n5 {
		margin-bottom:-3rem !important 
   }
	.ml-md-n5, .mx-md-n5 {
		margin-left:-3rem !important 
   }
	.m-md-auto {
		margin:auto !important 
   }
	.mt-md-auto, .my-md-auto {
		margin-top:auto !important 
   }
	.mr-md-auto, .mx-md-auto {
		margin-right:auto !important 
   }
	.mb-md-auto, .my-md-auto {
		margin-bottom:auto !important 
   }
	.ml-md-auto, .mx-md-auto {
		margin-left:auto !important 
   }
}
@media (min-width:992px) {
	.m-lg-0 {
		margin:0 !important 
   }
	.mt-lg-0, .my-lg-0 {
		margin-top:0 !important 
   }
	.mr-lg-0, .mx-lg-0 {
		margin-right:0 !important 
   }
	.mb-lg-0, .my-lg-0 {
		margin-bottom:0 !important 
   }
	.ml-lg-0, .mx-lg-0 {
		margin-left:0 !important 
   }
	.m-lg-1 {
		margin:.25rem !important 
   }
	.mt-lg-1, .my-lg-1 {
		margin-top:.25rem !important 
   }
	.mr-lg-1, .mx-lg-1 {
		margin-right:.25rem !important 
   }
	.mb-lg-1, .my-lg-1 {
		margin-bottom:.25rem !important 
   }
	.ml-lg-1, .mx-lg-1 {
		margin-left:.25rem !important 
   }
	.m-lg-2 {
		margin:.5rem !important 
   }
	.mt-lg-2, .my-lg-2 {
		margin-top:.5rem !important 
   }
	.mr-lg-2, .mx-lg-2 {
		margin-right:.5rem !important 
   }
	.mb-lg-2, .my-lg-2 {
		margin-bottom:.5rem !important 
   }
	.ml-lg-2, .mx-lg-2 {
		margin-left:.5rem !important 
   }
	.m-lg-3 {
		margin:1rem !important 
   }
	.mt-lg-3, .my-lg-3 {
		margin-top:1rem !important 
   }
	.mr-lg-3, .mx-lg-3 {
		margin-right:1rem !important 
   }
	.mb-lg-3, .my-lg-3 {
		margin-bottom:1rem !important 
   }
	.ml-lg-3, .mx-lg-3 {
		margin-left:1rem !important 
   }
	.m-lg-4 {
		margin:1.5rem !important 
   }
	.mt-lg-4, .my-lg-4 {
		margin-top:1.5rem !important 
   }
	.mr-lg-4, .mx-lg-4 {
		margin-right:1.5rem !important 
   }
	.mb-lg-4, .my-lg-4 {
		margin-bottom:1.5rem !important 
   }
	.ml-lg-4, .mx-lg-4 {
		margin-left:1.5rem !important 
   }
	.m-lg-5 {
		margin:3rem !important 
   }
	.mt-lg-5, .my-lg-5 {
		margin-top:3rem !important 
   }
	.mr-lg-5, .mx-lg-5 {
		margin-right:3rem !important 
   }
	.mb-lg-5, .my-lg-5 {
		margin-bottom:3rem !important 
   }
	.ml-lg-5, .mx-lg-5 {
		margin-left:3rem !important 
   }
	.p-lg-0 {
		padding:0 !important 
   }
	.pt-lg-0, .py-lg-0 {
		padding-top:0 !important 
   }
	.pr-lg-0, .px-lg-0 {
		padding-right:0 !important 
   }
	.pb-lg-0, .py-lg-0 {
		padding-bottom:0 !important 
   }
	.pl-lg-0, .px-lg-0 {
		padding-left:0 !important 
   }
	.p-lg-1 {
		padding:.25rem !important 
   }
	.pt-lg-1, .py-lg-1 {
		padding-top:.25rem !important 
   }
	.pr-lg-1, .px-lg-1 {
		padding-right:.25rem !important 
   }
	.pb-lg-1, .py-lg-1 {
		padding-bottom:.25rem !important 
   }
	.pl-lg-1, .px-lg-1 {
		padding-left:.25rem !important 
   }
	.p-lg-2 {
		padding:.5rem !important 
   }
	.pt-lg-2, .py-lg-2 {
		padding-top:.5rem !important 
   }
	.pr-lg-2, .px-lg-2 {
		padding-right:.5rem !important 
   }
	.pb-lg-2, .py-lg-2 {
		padding-bottom:.5rem !important 
   }
	.pl-lg-2, .px-lg-2 {
		padding-left:.5rem !important 
   }
	.p-lg-3 {
		padding:1rem !important 
   }
	.pt-lg-3, .py-lg-3 {
		padding-top:1rem !important 
   }
	.pr-lg-3, .px-lg-3 {
		padding-right:1rem !important 
   }
	.pb-lg-3, .py-lg-3 {
		padding-bottom:1rem !important 
   }
	.pl-lg-3, .px-lg-3 {
		padding-left:1rem !important 
   }
	.p-lg-4 {
		padding:1.5rem !important 
   }
	.pt-lg-4, .py-lg-4 {
		padding-top:1.5rem !important 
   }
	.pr-lg-4, .px-lg-4 {
		padding-right:1.5rem !important 
   }
	.pb-lg-4, .py-lg-4 {
		padding-bottom:1.5rem !important 
   }
	.pl-lg-4, .px-lg-4 {
		padding-left:1.5rem !important 
   }
	.p-lg-5 {
		padding:3rem !important 
   }
	.pt-lg-5, .py-lg-5 {
		padding-top:3rem !important 
   }
	.pr-lg-5, .px-lg-5 {
		padding-right:3rem !important 
   }
	.pb-lg-5, .py-lg-5 {
		padding-bottom:3rem !important 
   }
	.pl-lg-5, .px-lg-5 {
		padding-left:3rem !important 
   }
	.m-lg-n1 {
		margin:-.25rem !important 
   }
	.mt-lg-n1, .my-lg-n1 {
		margin-top:-.25rem !important 
   }
	.mr-lg-n1, .mx-lg-n1 {
		margin-right:-.25rem !important 
   }
	.mb-lg-n1, .my-lg-n1 {
		margin-bottom:-.25rem !important 
   }
	.ml-lg-n1, .mx-lg-n1 {
		margin-left:-.25rem !important 
   }
	.m-lg-n2 {
		margin:-.5rem !important 
   }
	.mt-lg-n2, .my-lg-n2 {
		margin-top:-.5rem !important 
   }
	.mr-lg-n2, .mx-lg-n2 {
		margin-right:-.5rem !important 
   }
	.mb-lg-n2, .my-lg-n2 {
		margin-bottom:-.5rem !important 
   }
	.ml-lg-n2, .mx-lg-n2 {
		margin-left:-.5rem !important 
   }
	.m-lg-n3 {
		margin:-1rem !important 
   }
	.mt-lg-n3, .my-lg-n3 {
		margin-top:-1rem !important 
   }
	.mr-lg-n3, .mx-lg-n3 {
		margin-right:-1rem !important 
   }
	.mb-lg-n3, .my-lg-n3 {
		margin-bottom:-1rem !important 
   }
	.ml-lg-n3, .mx-lg-n3 {
		margin-left:-1rem !important 
   }
	.m-lg-n4 {
		margin:-1.5rem !important 
   }
	.mt-lg-n4, .my-lg-n4 {
		margin-top:-1.5rem !important 
   }
	.mr-lg-n4, .mx-lg-n4 {
		margin-right:-1.5rem !important 
   }
	.mb-lg-n4, .my-lg-n4 {
		margin-bottom:-1.5rem !important 
   }
	.ml-lg-n4, .mx-lg-n4 {
		margin-left:-1.5rem !important 
   }
	.m-lg-n5 {
		margin:-3rem !important 
   }
	.mt-lg-n5, .my-lg-n5 {
		margin-top:-3rem !important 
   }
	.mr-lg-n5, .mx-lg-n5 {
		margin-right:-3rem !important 
   }
	.mb-lg-n5, .my-lg-n5 {
		margin-bottom:-3rem !important 
   }
	.ml-lg-n5, .mx-lg-n5 {
		margin-left:-3rem !important 
   }
	.m-lg-auto {
		margin:auto !important 
   }
	.mt-lg-auto, .my-lg-auto {
		margin-top:auto !important 
   }
	.mr-lg-auto, .mx-lg-auto {
		margin-right:auto !important 
   }
	.mb-lg-auto, .my-lg-auto {
		margin-bottom:auto !important 
   }
	.ml-lg-auto, .mx-lg-auto {
		margin-left:auto !important 
   }
}
@media (min-width:1200px) {
	.m-xl-0 {
		margin:0 !important 
   }
	.mt-xl-0, .my-xl-0 {
		margin-top:0 !important 
   }
	.mr-xl-0, .mx-xl-0 {
		margin-right:0 !important 
   }
	.mb-xl-0, .my-xl-0 {
		margin-bottom:0 !important 
   }
	.ml-xl-0, .mx-xl-0 {
		margin-left:0 !important 
   }
	.m-xl-1 {
		margin:.25rem !important 
   }
	.mt-xl-1, .my-xl-1 {
		margin-top:.25rem !important 
   }
	.mr-xl-1, .mx-xl-1 {
		margin-right:.25rem !important 
   }
	.mb-xl-1, .my-xl-1 {
		margin-bottom:.25rem !important 
   }
	.ml-xl-1, .mx-xl-1 {
		margin-left:.25rem !important 
   }
	.m-xl-2 {
		margin:.5rem !important 
   }
	.mt-xl-2, .my-xl-2 {
		margin-top:.5rem !important 
   }
	.mr-xl-2, .mx-xl-2 {
		margin-right:.5rem !important 
   }
	.mb-xl-2, .my-xl-2 {
		margin-bottom:.5rem !important 
   }
	.ml-xl-2, .mx-xl-2 {
		margin-left:.5rem !important 
   }
	.m-xl-3 {
		margin:1rem !important 
   }
	.mt-xl-3, .my-xl-3 {
		margin-top:1rem !important 
   }
	.mr-xl-3, .mx-xl-3 {
		margin-right:1rem !important 
   }
	.mb-xl-3, .my-xl-3 {
		margin-bottom:1rem !important 
   }
	.ml-xl-3, .mx-xl-3 {
		margin-left:1rem !important 
   }
	.m-xl-4 {
		margin:1.5rem !important 
   }
	.mt-xl-4, .my-xl-4 {
		margin-top:1.5rem !important 
   }
	.mr-xl-4, .mx-xl-4 {
		margin-right:1.5rem !important 
   }
	.mb-xl-4, .my-xl-4 {
		margin-bottom:1.5rem !important 
   }
	.ml-xl-4, .mx-xl-4 {
		margin-left:1.5rem !important 
   }
	.m-xl-5 {
		margin:3rem !important 
   }
	.mt-xl-5, .my-xl-5 {
		margin-top:3rem !important 
   }
	.mr-xl-5, .mx-xl-5 {
		margin-right:3rem !important 
   }
	.mb-xl-5, .my-xl-5 {
		margin-bottom:3rem !important 
   }
	.ml-xl-5, .mx-xl-5 {
		margin-left:3rem !important 
   }
	.p-xl-0 {
		padding:0 !important 
   }
	.pt-xl-0, .py-xl-0 {
		padding-top:0 !important 
   }
	.pr-xl-0, .px-xl-0 {
		padding-right:0 !important 
   }
	.pb-xl-0, .py-xl-0 {
		padding-bottom:0 !important 
   }
	.pl-xl-0, .px-xl-0 {
		padding-left:0 !important 
   }
	.p-xl-1 {
		padding:.25rem !important 
   }
	.pt-xl-1, .py-xl-1 {
		padding-top:.25rem !important 
   }
	.pr-xl-1, .px-xl-1 {
		padding-right:.25rem !important 
   }
	.pb-xl-1, .py-xl-1 {
		padding-bottom:.25rem !important 
   }
	.pl-xl-1, .px-xl-1 {
		padding-left:.25rem !important 
   }
	.p-xl-2 {
		padding:.5rem !important 
   }
	.pt-xl-2, .py-xl-2 {
		padding-top:.5rem !important 
   }
	.pr-xl-2, .px-xl-2 {
		padding-right:.5rem !important 
   }
	.pb-xl-2, .py-xl-2 {
		padding-bottom:.5rem !important 
   }
	.pl-xl-2, .px-xl-2 {
		padding-left:.5rem !important 
   }
	.p-xl-3 {
		padding:1rem !important 
   }
	.pt-xl-3, .py-xl-3 {
		padding-top:1rem !important 
   }
	.pr-xl-3, .px-xl-3 {
		padding-right:1rem !important 
   }
	.pb-xl-3, .py-xl-3 {
		padding-bottom:1rem !important 
   }
	.pl-xl-3, .px-xl-3 {
		padding-left:1rem !important 
   }
	.p-xl-4 {
		padding:1.5rem !important 
   }
	.pt-xl-4, .py-xl-4 {
		padding-top:1.5rem !important 
   }
	.pr-xl-4, .px-xl-4 {
		padding-right:1.5rem !important 
   }
	.pb-xl-4, .py-xl-4 {
		padding-bottom:1.5rem !important 
   }
	.pl-xl-4, .px-xl-4 {
		padding-left:1.5rem !important 
   }
	.p-xl-5 {
		padding:3rem !important 
   }
	.pt-xl-5, .py-xl-5 {
		padding-top:3rem !important 
   }
	.pr-xl-5, .px-xl-5 {
		padding-right:3rem !important 
   }
	.pb-xl-5, .py-xl-5 {
		padding-bottom:3rem !important 
   }
	.pl-xl-5, .px-xl-5 {
		padding-left:3rem !important 
   }
	.m-xl-n1 {
		margin:-.25rem !important 
   }
	.mt-xl-n1, .my-xl-n1 {
		margin-top:-.25rem !important 
   }
	.mr-xl-n1, .mx-xl-n1 {
		margin-right:-.25rem !important 
   }
	.mb-xl-n1, .my-xl-n1 {
		margin-bottom:-.25rem !important 
   }
	.ml-xl-n1, .mx-xl-n1 {
		margin-left:-.25rem !important 
   }
	.m-xl-n2 {
		margin:-.5rem !important 
   }
	.mt-xl-n2, .my-xl-n2 {
		margin-top:-.5rem !important 
   }
	.mr-xl-n2, .mx-xl-n2 {
		margin-right:-.5rem !important 
   }
	.mb-xl-n2, .my-xl-n2 {
		margin-bottom:-.5rem !important 
   }
	.ml-xl-n2, .mx-xl-n2 {
		margin-left:-.5rem !important 
   }
	.m-xl-n3 {
		margin:-1rem !important 
   }
	.mt-xl-n3, .my-xl-n3 {
		margin-top:-1rem !important 
   }
	.mr-xl-n3, .mx-xl-n3 {
		margin-right:-1rem !important 
   }
	.mb-xl-n3, .my-xl-n3 {
		margin-bottom:-1rem !important 
   }
	.ml-xl-n3, .mx-xl-n3 {
		margin-left:-1rem !important 
   }
	.m-xl-n4 {
		margin:-1.5rem !important 
   }
	.mt-xl-n4, .my-xl-n4 {
		margin-top:-1.5rem !important 
   }
	.mr-xl-n4, .mx-xl-n4 {
		margin-right:-1.5rem !important 
   }
	.mb-xl-n4, .my-xl-n4 {
		margin-bottom:-1.5rem !important 
   }
	.ml-xl-n4, .mx-xl-n4 {
		margin-left:-1.5rem !important 
   }
	.m-xl-n5 {
		margin:-3rem !important 
   }
	.mt-xl-n5, .my-xl-n5 {
		margin-top:-3rem !important 
   }
	.mr-xl-n5, .mx-xl-n5 {
		margin-right:-3rem !important 
   }
	.mb-xl-n5, .my-xl-n5 {
		margin-bottom:-3rem !important 
   }
	.ml-xl-n5, .mx-xl-n5 {
		margin-left:-3rem !important 
   }
	.m-xl-auto {
		margin:auto !important 
   }
	.mt-xl-auto, .my-xl-auto {
		margin-top:auto !important 
   }
	.mr-xl-auto, .mx-xl-auto {
		margin-right:auto !important 
   }
	.mb-xl-auto, .my-xl-auto {
		margin-bottom:auto !important 
   }
	.ml-xl-auto, .mx-xl-auto {
		margin-left:auto !important 
   }
}
.text-monospace {
	font-family:SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important 
}
.text-justify {
	text-align:justify !important 
}
.text-wrap {
	white-space:normal !important 
}
.text-nowrap {
	white-space:nowrap !important 
}
.text-truncate {
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap 
}
.text-left {
	text-align:left !important 
}
.text-right {
	text-align:right !important 
}
.text-center {
	text-align:center !important 
}
@media (min-width:576px) {
	.text-sm-left {
		text-align:left !important 
   }
	.text-sm-right {
		text-align:right !important 
   }
	.text-sm-center {
		text-align:center !important 
   }
}
@media (min-width:768px) {
	.text-md-left {
		text-align:left !important 
   }
	.text-md-right {
		text-align:right !important 
   }
	.text-md-center {
		text-align:center !important 
   }
}
@media (min-width:992px) {
	.text-lg-left {
		text-align:left !important 
   }
	.text-lg-right {
		text-align:right !important 
   }
	.text-lg-center {
		text-align:center !important 
   }
}
@media (min-width:1200px) {
	.text-xl-left {
		text-align:left !important 
   }
	.text-xl-right {
		text-align:right !important 
   }
	.text-xl-center {
		text-align:center !important 
   }
}
.text-lowercase {
	text-transform:lowercase !important 
}
.sidebar .sidebar-heading, .text-uppercase {
	text-transform:uppercase !important 
}
.text-capitalize {
	text-transform:capitalize !important 
}
.font-weight-bold {
	font-weight:600 !important 
}
.font-italic {
	font-style:italic !important 
}
.text-white {
	color:#fff !important 
}
.text-primary {
	color:#01315a !important 
}
a.text-primary:focus, a.text-primary:hover {
	color:#224abe !important 
}
.text-secondary {
	color:#858796 !important 
}
a.text-secondary:focus, a.text-secondary:hover {
	color:#60616f !important 
}
.text-success {
	color:#058343 !important 
}
a.text-success:focus, a.text-success:hover {
	color:#13855c !important 
}
.text-info {
	color:#36b9cc !important 
}
a.text-info:focus, a.text-info:hover {
	color:#258391 !important 
}
.text-warning {
	color:#dea20b !important 
}
a.text-warning:focus, a.text-warning:hover {
	color:#dda20a !important 
}
.text-danger {
	color:#e74a3b !important 
}
a.text-danger:focus, a.text-danger:hover {
	color:#be2617 !important 
}
.text-light {
	color:#f8f9fc !important 
}
a.text-light:focus, a.text-light:hover {
	color:#c2cbe5 !important 
}
.text-dark {
	color:#212B36 !important 
}
a.text-dark:focus, a.text-dark:hover {
	color:#373840 !important 
}
.text-body {
	color:#858796 !important 
}
.text-muted {
	color:#858796 !important 
}
.text-black-50 {
	color:rgba(0, 0, 0, .5) !important 
}
.text-white-50 {
	color:rgba(255, 255, 255, .5) !important 
}
.text-black-75 {
	color:rgba(0, 0, 0, .75) !important 
}
.text-white-75 {
	color:rgba(255, 255, 255, .75) !important 
}
.text-hide {
	font:0/0 a;
	color:transparent;
	text-shadow:none;
	background-color:transparent;
	border:0 
}
.text-decoration-none {
	text-decoration:none !important 
}
html {
	position:relative;
	min-height:100% 
}
body {
	height:100% 
}
a:focus {
	outline:0 
}
#wrapper {
	display:flex 
}
#wrapper #content-wrapper {
	background-color:#f9fafd;
	width:100%;
	overflow-x:hidden;
   /* margin-left: 150px;
	*/
	transition: margin 0.4s ease;
	z-index: 1;
}
#wrapper #content-wrapper {
	min-height: 250px;
}
@media(min-width: 576px) {
	#wrapper #content-wrapper {
		min-height: calc(100vh - 330px);
   }
}
@media(min-width: 768px) {
	#wrapper #content-wrapper {
		min-height: calc(100vh - 270px);
   }
}
body.bg-white #wrapper #content-wrapper {
	background-color:#ffffff;
}
#wrapper #content-wrapper #content {
	flex:1 0 auto 
}
.container, .container-fluid {
	padding-left:1rem;
	padding-right:1rem 
}
@-webkit-keyframes growIn {
	0% {
		transform:scale(.9);
		opacity:0 
   }
	100% {
		transform:scale(1);
		opacity:1 
   }
}
@keyframes growIn {
	0% {
		transform:scale(.9);
		opacity:0 
   }
	100% {
		transform:scale(1);
		opacity:1 
   }
}
.animated--grow-in, .sidebar .nav-item .collapse {
	-webkit-animation-name:growIn;
	animation-name:growIn;
	-webkit-animation-duration:.2s;
	animation-duration:.2s;
	-webkit-animation-timing-function:transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
	animation-timing-function:transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1) 
}
@-webkit-keyframes fadeIn {
	0% {
		opacity:0 
   }
	100% {
		opacity:1 
   }
}
@keyframes fadeIn {
	0% {
		opacity:0 
   }
	100% {
		opacity:1 
   }
}
.animated--fade-in {
	-webkit-animation-name:fadeIn;
	animation-name:fadeIn;
	-webkit-animation-duration:.2s;
	animation-duration:.2s;
	-webkit-animation-timing-function:opacity cubic-bezier(0, 1, .4, 1);
	animation-timing-function:opacity cubic-bezier(0, 1, .4, 1) 
}
.o-hidden {
	overflow:hidden !important 
}
.text-xs {
	font-size:.7rem 
}
.text-lg {
	font-size:1.2rem 
}
.icon-circle {
	height:2.5rem;
	width:2.5rem;
	border-radius:100%;
	display:flex;
	align-items:center;
	justify-content:center 
}
.sidebar .nav-item .nav-link, .topbar .nav-item .nav-link {
	position:relative 
}
.sidebar .nav-item .nav-link .badge-counter, .topbar .nav-item .nav-link .badge-counter {
	position:absolute;
	transform:scale(.7);
	transform-origin:top right;
	right:.25rem;
	margin-top:-.25rem 
}
.sidebar .nav-item .nav-link .img-profile, .topbar .nav-item .nav-link .img-profile {
	height:2rem;
	width:2rem 
}
.topbar {
	height:60px 
}
.topbar #sidebarToggleTop {
	width: 40px;
	min-width: 40px;
	height: 40px;
	padding: 0px;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #637381;
	transition:background-color .15s ease-in-out 
}
.topbar #sidebarToggleTop:hover {
	color: #212B36;
	background-color:#eaecf4 
}
.topbar .topbar-divider {
	width:0;
	border-right:1px solid #e3e6f0;
	height:calc(60px - 2rem);
	margin:auto 1rem 
}
.sidebar {
	position: fixed;
	left: 0;
	top: 0;
	overflow-x:hidden;
	overflow-y: scroll;
	height: 100vh;
	width:270px !important;
	padding-top: 15px;
	padding-bottom: 15px;
}
.sidebar .nav-item {
	position:relative;
	padding: 0px 10px;
}
.sidebar .nav-item .nav-link {
	padding:.35rem 1rem;
}
.sidebar .nav-item .nav-link span {
	font-size:14px;
	font-weight: 500;
}
.sidebar .nav-item .collapse {
	position:absolute;
	left:calc(270px + 1.5rem / 2);
	z-index:1;
	top:2px 
}
.sidebar .nav-item .collapse .collapse-inner {
	border-radius:.35rem;
	box-shadow:0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) 
}
.sidebar .nav-item .collapsing {
	display:none;
	transition:none 
}
.sidebar .nav-item .collapse .collapse-inner, .sidebar .nav-item .collapsing .collapse-inner {
	padding:.5rem 0;
	min-width:10rem;
	font-size:.85rem;
	margin:0 0 1rem 0 
}
.sidebar .nav-item .collapse .collapse-inner .collapse-header, .sidebar .nav-item .collapsing .collapse-inner .collapse-header {
	margin:0;
	white-space:nowrap;
	padding:.5rem 1.5rem;
	text-transform:uppercase;
	font-weight:800;
	font-size:.65rem;
	color:#b7b9cc 
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item, .sidebar .nav-item .collapsing .collapse-inner .collapse-item {
	padding:.5rem 1rem;
	margin:0 .5rem;
	display:block;
	color:#3a3b45;
	text-decoration:none;
	border-radius:.35rem;
	white-space:nowrap 
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover, .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
	background-color:#eaecf4 
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:active, .sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
	background-color:#dddfeb 
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item.active, .sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
	color:#4e73df;
	font-weight:700 
}
.sidebar #sidebarToggle {
	width:2.5rem;
	height:2.5rem;
	text-align:center;
	margin-bottom:1rem;
	cursor:pointer 
}
.sidebar #sidebarToggle::after {
	font-weight:900;
	content:'\f104';
	font-family:'Font Awesome 5 Free';
	margin-right:.1rem 
}
.sidebar #sidebarToggle:hover {
	text-decoration:none 
}
.sidebar #sidebarToggle:focus {
	outline:0 
}
.navbar-brand img {
	height: 32px;
}
@media(min-width: 576px) {
	.navbar-brand img {
		height: 38px;
   }
}
.sidebar.toggled #sidebarToggle::after {
	content:'\f105';
	font-family:'Font Awesome 5 Free';
	margin-left:.25rem 
}
.sidebar .sidebar-brand {
	height:60px;
	text-decoration:none;
	font-size:1rem;
	font-weight:800;
	padding:1.5rem 1rem;
	text-align:center;
	text-transform:uppercase;
	letter-spacing:.05rem;
	z-index:1 
}
.sidebar .sidebar-brand .sidebar-brand-icon i {
	font-size:2rem 
}
.sidebar .sidebar-brand .sidebar-brand-text {
	display:none 
}
.sidebar hr.sidebar-divider {
	margin:0 1rem 1rem 
}
.sidebar .sidebar-heading {
	text-align:center;
	padding:0 1rem;
	font-weight:800;
	font-size:.65rem 
}
@media (max-width:767px) {
	.app-sidebar-toggled #content-wrapper {
		margin-left: 0px !important;
   }
}
@media (min-width:768px) {
	.sidebar .nav-item .collapse {
		position:relative;
		left:0;
		z-index:1;
		top:0;
		-webkit-animation:none;
		animation:none 
   }
	.sidebar .nav-item .collapse .collapse-inner {
		border-radius:0;
		box-shadow:none 
   }
	.sidebar .nav-item .collapsing {
		display:block;
		transition:height .15s ease 
   }
	.sidebar .nav-item .collapse, .sidebar .nav-item .collapsing {
		margin:0 1rem 
   }
	.sidebar .nav-item .nav-link i {
		font-size:.85rem;
		margin-right:.25rem 
   }
	.sidebar .nav-item .nav-link {
		padding:.5rem 1rem;
   }
	.sidebar .nav-item .nav-link span {
		font-size:16px;
   }
	.sidebar .nav-item .nav-link[data-toggle=collapse]::after {
		width:1rem;
		text-align:center;
		float:right;
		vertical-align:0;
		border:0;
		font-weight:900;
		content:'\f107';
		font-family:'Font Awesome 5 Free' 
   }
	.sidebar .nav-item .nav-link[data-toggle=collapse].collapsed::after {
		content:'\f105' 
   }
	.sidebar .sidebar-brand .sidebar-brand-icon i {
		font-size:2rem 
   }
	.sidebar .sidebar-brand .sidebar-brand-text {
		display:inline 
   }
	.sidebar .sidebar-heading {
		text-align:left 
   }
}
.sidebar {
	position: fixed;
	left: -300px;
	top: 60px;
	overflow-x: hidden;
	overflow-y: scroll;
	height: calc(100vh - 60px);
	width: 240px !important;
	padding-top: 15px;
	padding-bottom: 15px;
	z-index: 1;
	transition: left 0.4s ease;
	box-shadow: 4px 5px 10px -6px #888;
}
.app-sidebar-toggled .sidebar {
	left: 0px;
}
.sidebar-avatar {
	height: 30px;
	width: 30px;
	margin-right: 10px;
	object-fit: cover;
	border-radius: 100px;
	box-shadow: 0 8px 16px 0 rgb(0 171 85 / 24%);
	background-color: #ffffff;
	border: 1px solid #ddd;
}
@media (min-width:400px) {
	.sidebar {
		width: 270px !important;
   }
	.sidebar-avatar {
		height: 40px;
		width: 40px;
   }
}
.sidebar-dark .sidebar-brand {
	color:#fff 
}
.sidebar-dark hr.sidebar-divider {
	border-top:1px solid rgba(255, 255, 255, .15) 
}
.sidebar-dark .sidebar-heading {
	color:rgba(255, 255, 255, .4) 
}
.sidebar-dark .nav-item .nav-link {
	color:#212B36CC 
}
.sidebar-dark .nav-item .nav-link i {
	color:#212B36CC 
}
.sidebar-dark .nav-item .nav-link:active, .sidebar-dark .nav-item .nav-link:focus, .sidebar-dark .nav-item .nav-link:hover {
	color:#212B36 
}
.sidebar-dark .nav-item .nav-link:active i, .sidebar-dark .nav-item .nav-link:focus i, .sidebar-dark .nav-item .nav-link:hover i {
	color:#212B36 
}
.sidebar-dark .nav-item .nav-link[data-toggle=collapse]::after {
	color:rgba(255, 255, 255, .5) 
}
.sidebar-dark .nav-item {
	margin-bottom: 5px;
}
.sidebar-dark .nav-item .nav-link:hover {
	border-radius: 5px;
	background-color:rgba(0, 171, 85, 0.08);
	color:#212B36;
}
.sidebar-dark .nav-item.active .nav-link {
	border-radius: 5px;
	background-color:rgba(0, 171, 85, 0.08);
	color:#212B36;
}
.sidebar-dark .nav-item .nav-link {
	color:#212B36CC;
}
.sidebar-dark .nav-item .nav-link i {
	color:#212B36;
}
.sidebar-dark #sidebarToggle {
	background-color:rgba(255, 255, 255, .2) 
}
.sidebar-dark #sidebarToggle::after {
	color:rgba(255, 255, 255, .5) 
}
.sidebar-dark #sidebarToggle:hover {
	background-color:rgba(255, 255, 255, .25) 
}
.sidebar-dark.toggled #sidebarToggle::after {
	color:rgba(255, 255, 255, .5) 
}
.sidebar-dark .nav-item.highlight .nav-link {
	color: #fff;
	background-color: #00AB55;
	box-shadow: 0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%);
	text-align: center;
	line-height: 1.4;
	border-radius: 5px;
}
.card .card-header[data-toggle=collapse] {
	text-decoration:none;
	position:relative;
	padding:.75rem 3.25rem .75rem 1.25rem 
}
.card .card-header[data-toggle=collapse]::after {
	position:absolute;
	right:0;
	top:0;
	padding-right:1.725rem;
	line-height:51px;
	font-weight:900;
	content:'\f107';
	font-family:'Font Awesome 5 Free';
	color:#d1d3e2 
}
.card .card-header[data-toggle=collapse].collapsed {
	border-radius:.35rem 
}
.card .card-header[data-toggle=collapse].collapsed::after {
	content:'\f105' 
}
form.user .custom-checkbox.small label {
	line-height:1.5rem 
}
form.user .form-control-user {
	font-size:.8rem;
	border-radius:10rem;
	padding:1.5rem 1rem 
}
form.user .btn-user {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	background-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
	user-select: none;
	vertical-align: middle;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	text-decoration: none;
	color: inherit;
	font-weight: 700;
	line-height: 1.7142857142857142;
	font-size: 0.9375rem;
	text-transform: capitalize;
	font-family: 'Inter',sans-serif;
	min-width: 64px;
	padding: 8px 22px;
	border-radius: 8px;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	color: #fff;
	background-color: #00AB55;
	box-shadow: 0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%);
	width: 100%;
	box-shadow: 0 8px 16px 0 rgb(0 171 85 / 24%);
	height: 48px;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
form.user .btn-user:active, form.user .btn-user:focus {
	color: #fff !important;
	background-color: #00AB55 !important;
	box-shadow: 0px 5px 5px -3px rgb(145 158 171 / 20%), 0px 8px 10px 1px rgb(145 158 171 / 14%), 0px 3px 14px 2px rgb(145 158 171 / 12%) !important;
}
form.user .btn-user:hover {
	box-shadow: none !important;
	text-decoration: none !important;
	background-color: #007B55 !important;
	box-shadow: 0px 2px 4px -1px rgb(145 158 171 / 20%), 0px 4px 5px 0px rgb(145 158 171 / 14%), 0px 1px 10px 0px rgb(145 158 171 / 12%) !important;
}
form.user .login-cta {
	font-family: 'Inter',sans-serif;
	font-weight: 400;
	font-size: 0.875rem;
	text-align: center;
}
form.user .login-cta a {
	font-weight: 600;
	color: #00AB55;
}
form.user .register-agree {
	font-size: 0.875rem;
	font-family: 'Inter',sans-serif;
	font-weight: 400;
	text-align: center;
	color: #637381;
}
form.user .register-agree a {
	text-decoration: underline;
	text-decoration-color: rgba(33, 43, 54, 0.4);
	color: #212B36;
}
.error {
	color:#5a5c69;
	font-size:7rem;
	position:relative;
	line-height:1;
	width:12.5rem 
}
@-webkit-keyframes noise-anim {
	0% {
		clip:rect(32px, 9999px, 16px, 0) 
   }
	5% {
		clip:rect(5px, 9999px, 24px, 0) 
   }
	10% {
		clip:rect(77px, 9999px, 87px, 0) 
   }
	15% {
		clip:rect(91px, 9999px, 95px, 0) 
   }
	20% {
		clip:rect(74px, 9999px, 9px, 0) 
   }
	25% {
		clip:rect(37px, 9999px, 32px, 0) 
   }
	30% {
		clip:rect(56px, 9999px, 27px, 0) 
   }
	35% {
		clip:rect(35px, 9999px, 33px, 0) 
   }
	40% {
		clip:rect(89px, 9999px, 6px, 0) 
   }
	45% {
		clip:rect(81px, 9999px, 77px, 0) 
   }
	50% {
		clip:rect(64px, 9999px, 69px, 0) 
   }
	55% {
		clip:rect(12px, 9999px, 11px, 0) 
   }
	60% {
		clip:rect(59px, 9999px, 11px, 0) 
   }
	65% {
		clip:rect(69px, 9999px, 59px, 0) 
   }
	70% {
		clip:rect(74px, 9999px, 65px, 0) 
   }
	75% {
		clip:rect(56px, 9999px, 79px, 0) 
   }
	80% {
		clip:rect(80px, 9999px, 64px, 0) 
   }
	85% {
		clip:rect(87px, 9999px, 29px, 0) 
   }
	90% {
		clip:rect(16px, 9999px, 21px, 0) 
   }
	95% {
		clip:rect(69px, 9999px, 43px, 0) 
   }
	100% {
		clip:rect(75px, 9999px, 63px, 0) 
   }
}
@keyframes noise-anim {
	0% {
		clip:rect(32px, 9999px, 16px, 0) 
   }
	5% {
		clip:rect(5px, 9999px, 24px, 0) 
   }
	10% {
		clip:rect(77px, 9999px, 87px, 0) 
   }
	15% {
		clip:rect(91px, 9999px, 95px, 0) 
   }
	20% {
		clip:rect(74px, 9999px, 9px, 0) 
   }
	25% {
		clip:rect(37px, 9999px, 32px, 0) 
   }
	30% {
		clip:rect(56px, 9999px, 27px, 0) 
   }
	35% {
		clip:rect(35px, 9999px, 33px, 0) 
   }
	40% {
		clip:rect(89px, 9999px, 6px, 0) 
   }
	45% {
		clip:rect(81px, 9999px, 77px, 0) 
   }
	50% {
		clip:rect(64px, 9999px, 69px, 0) 
   }
	55% {
		clip:rect(12px, 9999px, 11px, 0) 
   }
	60% {
		clip:rect(59px, 9999px, 11px, 0) 
   }
	65% {
		clip:rect(69px, 9999px, 59px, 0) 
   }
	70% {
		clip:rect(74px, 9999px, 65px, 0) 
   }
	75% {
		clip:rect(56px, 9999px, 79px, 0) 
   }
	80% {
		clip:rect(80px, 9999px, 64px, 0) 
   }
	85% {
		clip:rect(87px, 9999px, 29px, 0) 
   }
	90% {
		clip:rect(16px, 9999px, 21px, 0) 
   }
	95% {
		clip:rect(69px, 9999px, 43px, 0) 
   }
	100% {
		clip:rect(75px, 9999px, 63px, 0) 
   }
}
.error:after {
	content:attr(data-text);
	position:absolute;
	left:2px;
	text-shadow:-1px 0 #e74a3b;
	top:0;
	color:#5a5c69;
	background:#f8f9fc;
	overflow:hidden;
	clip:rect(0, 900px, 0, 0);
	animation:noise-anim 2s infinite linear alternate-reverse 
}
@-webkit-keyframes noise-anim-2 {
	0% {
		clip:rect(12px, 9999px, 52px, 0) 
   }
	5% {
		clip:rect(42px, 9999px, 39px, 0) 
   }
	10% {
		clip:rect(64px, 9999px, 36px, 0) 
   }
	15% {
		clip:rect(52px, 9999px, 15px, 0) 
   }
	20% {
		clip:rect(79px, 9999px, 7px, 0) 
   }
	25% {
		clip:rect(17px, 9999px, 41px, 0) 
   }
	30% {
		clip:rect(15px, 9999px, 20px, 0) 
   }
	35% {
		clip:rect(62px, 9999px, 87px, 0) 
   }
	40% {
		clip:rect(94px, 9999px, 11px, 0) 
   }
	45% {
		clip:rect(49px, 9999px, 10px, 0) 
   }
	50% {
		clip:rect(82px, 9999px, 4px, 0) 
   }
	55% {
		clip:rect(70px, 9999px, 100px, 0) 
   }
	60% {
		clip:rect(62px, 9999px, 23px, 0) 
   }
	65% {
		clip:rect(51px, 9999px, 56px, 0) 
   }
	70% {
		clip:rect(41px, 9999px, 24px, 0) 
   }
	75% {
		clip:rect(6px, 9999px, 85px, 0) 
   }
	80% {
		clip:rect(96px, 9999px, 58px, 0) 
   }
	85% {
		clip:rect(16px, 9999px, 24px, 0) 
   }
	90% {
		clip:rect(40px, 9999px, 31px, 0) 
   }
	95% {
		clip:rect(91px, 9999px, 34px, 0) 
   }
	100% {
		clip:rect(87px, 9999px, 26px, 0) 
   }
}
@keyframes noise-anim-2 {
	0% {
		clip:rect(12px, 9999px, 52px, 0) 
   }
	5% {
		clip:rect(42px, 9999px, 39px, 0) 
   }
	10% {
		clip:rect(64px, 9999px, 36px, 0) 
   }
	15% {
		clip:rect(52px, 9999px, 15px, 0) 
   }
	20% {
		clip:rect(79px, 9999px, 7px, 0) 
   }
	25% {
		clip:rect(17px, 9999px, 41px, 0) 
   }
	30% {
		clip:rect(15px, 9999px, 20px, 0) 
   }
	35% {
		clip:rect(62px, 9999px, 87px, 0) 
   }
	40% {
		clip:rect(94px, 9999px, 11px, 0) 
   }
	45% {
		clip:rect(49px, 9999px, 10px, 0) 
   }
	50% {
		clip:rect(82px, 9999px, 4px, 0) 
   }
	55% {
		clip:rect(70px, 9999px, 100px, 0) 
   }
	60% {
		clip:rect(62px, 9999px, 23px, 0) 
   }
	65% {
		clip:rect(51px, 9999px, 56px, 0) 
   }
	70% {
		clip:rect(41px, 9999px, 24px, 0) 
   }
	75% {
		clip:rect(6px, 9999px, 85px, 0) 
   }
	80% {
		clip:rect(96px, 9999px, 58px, 0) 
   }
	85% {
		clip:rect(16px, 9999px, 24px, 0) 
   }
	90% {
		clip:rect(40px, 9999px, 31px, 0) 
   }
	95% {
		clip:rect(91px, 9999px, 34px, 0) 
   }
	100% {
		clip:rect(87px, 9999px, 26px, 0) 
   }
}
.error:before {
	content:attr(data-text);
	position:absolute;
	left:-2px;
	text-shadow:1px 0 #4e73df;
	top:0;
	color:#5a5c69;
	background:#f8f9fc;
	overflow:hidden;
	clip:rect(0, 900px, 0, 0);
	animation:noise-anim-2 3s infinite linear alternate-reverse 
}
.row-cols-1>* {
	-ms-flex:0 0 100%;
	flex:0 0 100%;
	max-width:100% 
}
.row-cols-2>* {
	-ms-flex:0 0 50%;
	flex:0 0 50%;
	max-width:50% 
}
.row-cols-3>* {
	-ms-flex:0 0 33.333333%;
	flex:0 0 33.333333%;
	max-width:33.333333% 
}
.row-cols-4>* {
	-ms-flex:0 0 25%;
	flex:0 0 25%;
	max-width:25% 
}
.row-cols-5>* {
	-ms-flex:0 0 20%;
	flex:0 0 20%;
	max-width:20% 
}
.row-cols-6>* {
	-ms-flex:0 0 16.666667%;
	flex:0 0 16.666667%;
	max-width:16.666667% 
}
@media (min-width:576px) {
	.row-cols-sm-1>* {
		-ms-flex:0 0 100%;
		flex:0 0 100%;
		max-width:100% 
   }
	.row-cols-sm-2>* {
		-ms-flex:0 0 50%;
		flex:0 0 50%;
		max-width:50% 
   }
	.row-cols-sm-3>* {
		-ms-flex:0 0 33.333333%;
		flex:0 0 33.333333%;
		max-width:33.333333% 
   }
	.row-cols-sm-4>* {
		-ms-flex:0 0 25%;
		flex:0 0 25%;
		max-width:25% 
   }
	.row-cols-sm-5>* {
		-ms-flex:0 0 20%;
		flex:0 0 20%;
		max-width:20% 
   }
	.row-cols-sm-6>* {
		-ms-flex:0 0 16.666667%;
		flex:0 0 16.666667%;
		max-width:16.666667% 
   }
}
@media (min-width:768px) {
	.row-cols-md-1>* {
		-ms-flex:0 0 100%;
		flex:0 0 100%;
		max-width:100% 
   }
	.row-cols-md-2>* {
		-ms-flex:0 0 50%;
		flex:0 0 50%;
		max-width:50% 
   }
	.row-cols-md-3>* {
		-ms-flex:0 0 33.333333%;
		flex:0 0 33.333333%;
		max-width:33.333333% 
   }
	.row-cols-md-4>* {
		-ms-flex:0 0 25%;
		flex:0 0 25%;
		max-width:25% 
   }
	.row-cols-md-5>* {
		-ms-flex:0 0 20%;
		flex:0 0 20%;
		max-width:20% 
   }
	.row-cols-md-6>* {
		-ms-flex:0 0 16.666667%;
		flex:0 0 16.666667%;
		max-width:16.666667% 
   }
}
@media (min-width:992px) {
	.row-cols-lg-1>* {
		-ms-flex:0 0 100%;
		flex:0 0 100%;
		max-width:100% 
   }
	.row-cols-lg-2>* {
		-ms-flex:0 0 50%;
		flex:0 0 50%;
		max-width:50% 
   }
	.row-cols-lg-3>* {
		-ms-flex:0 0 33.333333%;
		flex:0 0 33.333333%;
		max-width:33.333333% 
   }
	.row-cols-lg-4>* {
		-ms-flex:0 0 25%;
		flex:0 0 25%;
		max-width:25% 
   }
	.row-cols-lg-5>* {
		-ms-flex:0 0 20%;
		flex:0 0 20%;
		max-width:20% 
   }
	.row-cols-lg-6>* {
		-ms-flex:0 0 16.666667%;
		flex:0 0 16.666667%;
		max-width:16.666667% 
   }
}
@media (min-width:1200px) {
	.row-cols-xl-1>* {
		-ms-flex:0 0 100%;
		flex:0 0 100%;
		max-width:100% 
   }
	.row-cols-xl-2>* {
		-ms-flex:0 0 50%;
		flex:0 0 50%;
		max-width:50% 
   }
	.row-cols-xl-3>* {
		-ms-flex:0 0 33.333333%;
		flex:0 0 33.333333%;
		max-width:33.333333% 
   }
	.row-cols-xl-4>* {
		-ms-flex:0 0 25%;
		flex:0 0 25%;
		max-width:25% 
   }
	.row-cols-xl-5>* {
		-ms-flex:0 0 20%;
		flex:0 0 20%;
		max-width:20% 
   }
	.row-cols-xl-6>* {
		-ms-flex:0 0 16.666667%;
		flex:0 0 16.666667%;
		max-width:16.666667% 
   }
}
.validation-error {
	font-size: 14px;
	color: #e74a3b;
}
.w-100px {
	min-width: 100px;
}
.badge-table {
	line-height: 1.4;
	padding: 3px 10px;
	text-transform: uppercase;
   min-width: 85px 
}
.alert-msg-success, .alert-msg-danger, .alert-msg-warning {
	color: white;
	padding: 10px 12px;
	border-radius: .25rem;
	margin-bottom: 1rem;
}
.alert-msg-success {
	background-color: #00AB55;
}
.alert-msg-danger {
	background-color: #e52538;
}
.alert-msg-warning {
	background-color: #c96906;
}
.alert-msg-success *, .alert-msg-danger *, .alert-msg-warning * {
	color: white;
	text-transform: initial;
}
.alert-msg-success h3, .alert-msg-danger h3, .alert-msg-warning h3 {
	font-size: 15px;
	font-weight: 400 !important;
}
.btn-file {
	position: relative;
	overflow: hidden;
}
.btn-file input[type=file] {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	font-size: 100px;
	text-align: right;
	filter: alpha(opacity=0);
	opacity: 0;
	outline: none;
	background: white;
	cursor: inherit;
	display: block;
}
#img-upload {
	width: 100%;
}
.custom-file-container {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 100%;
	margin-bottom: 0px;
}
.custom-file-container-control {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	font-weight: 600;
	line-height: 1;
	font-size: 14px;
	text-transform: capitalize;
	font-family: 'Inter',sans-serif;
	min-width: 64px;
	padding: 5px 10px;
	border-radius: 8px;
	color: #fff;
	background-color: #00AB55;
	width: 100%;
	box-shadow: 0 8px 16px 0 rgb(0 171 85 / 24%);
	height: 40px;
	cursor: pointer;
}
.custom-file-container-control.disabled {
	background-color: #768d81;
	box-shadow: none;
	cursor: not-allowed;
}
.custom-file-container-input {
	width: 110px;
	height: 40px;
	box-sizing: border-box !important;
	margin: 0 !important;
	opacity: 0 !important;
}
@media (min-width: 576px) {
	.custom-file-container-input {
		width: 130px;
   }
}
.custom-file-container-preview div.custom-file-container-preview-item {
	position: relative;
	border: 1px solid #dedede;
	height: 100%;
	width: 100%;
	min-height: 173px;
	border-radius: .25rem;
   background: #f5f5f5;
}
.custom-file-container-preview img {
	max-height: 100%;
	max-width: calc(100% - 25px);
	width: auto;
	height: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.custom-file-container-preview div.custom-file-container-preview-nonimage p {
	margin: auto;
	font-size: 20px;
}
.custom-file-container-preview div.custom-file-container-preview-nonimage {
	display: flex;
	background: #2372ba !important;
	color: #fff;
}
.custom-file-container-preview-remove {
	color: #fff;
	z-index: 1;
	text-align: center;
	position: absolute;
	right: 4px;
	top: 6px;
	font-size: 14px;
	padding: 3px;
	cursor: pointer;
	background: #dc3545;
	border-radius: 100%;
	width: 22px;
	height: 22px;
}
.custom-file-container-preview-remove:hover {
	color: #fff;
}
.custom-file-container-preview-content {
	z-index: 1;
	position: absolute;
	bottom: 0px;
	background: rgba(0, 0, 0, 0.5);
	width: calc(100% - 16px);
	color: white;
	font-size: 13px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-height: 36px;
	line-height: 26px;
	padding: 5px 6px;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
	background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, .6) 100%);
	background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, .6) 100%);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, .6) 100%);
}
.custom-file-container-preview-extension {
	position: absolute;
	top: 8px;
	left: 17px;
	background: rgba(0, 0, 0, 0.5);
	width: auto;
	color: white;
	border-radius: 4px;
	font-size: 12px;
	line-height: 22px;
	z-index: 1;
	padding: 0px 5px;
}
.custom-file-container-preview-make-cover {
	position: absolute;
	top: 7px;
	left: 14px;
	background: #0E9877;
	width: auto;
	color: white;
	cursor: pointer;
	border-radius: 4px;
	font-size: 11px;
	line-height: 22px;
	z-index: 1;
	padding: 0px 4px;
}
.custom-file-container-preview-make-cover.active {
	background: #e8a405;
}
.custom-file-container-profile {
	margin: 0px auto 1rem;
	display: flex;
	width: 100px;
	height: 100px;
}
.custom-file-container-profile .custom-file-container-control {
	width: 100px;
	height: 100px;
	margin: auto;
	min-width: initial;
	padding: initial;
	border-radius: initial;
	color: initial;
	background-color: initial;
	width: initial;
	box-shadow: initial;
	height: initial;
}
.custom-file-container-profile .custom-file-container-control img {
	height: 100px;
	width: 100px;
	box-shadow: 0 8px 16px 0 rgb(0 171 85 / 24%);
	cursor: pointer;
	object-fit: cover;
	border-radius: 100px;
	border: 1px solid #ddd;
}
.custom-file-container-profile .custom-file-container-control .overlay {
	opacity: 0;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 11px;
	width: 100px;
	height: 100px;
	margin: auto;
	border-radius: 100px;
	background: #00000066;
	transition:opacity .15s ease;
	color: #f5f5f5;
}
.custom-file-container-profile .custom-file-container-control svg {
	margin-bottom: 5px;
}
.custom-file-container-profile .custom-file-container-control:hover .overlay {
	opacity: 1;
}
.row-cols-7>* {
	-ms-flex:0 0 14.285714%;
	flex:0 0 14.285714%;
	max-width:14.285714% 
}
.row-cols-8>* {
	-ms-flex:0 0 12.5%;
	flex:0 0 12.5%;
	max-width:12.5% 
}
.row-cols-9>* {
	-ms-flex:0 0 11.111111%;
	flex:0 0 11.111111%;
	max-width:11.111111% 
}
.row-cols-10>* {
	-ms-flex:0 0 10%;
	flex:0 0 10%;
	max-width:10% 
}
.row-cols-11>* {
	-ms-flex:0 0 9.090909%;
	flex:0 0 9.090909%;
	max-width:9.090909% 
}
.row-cols-12>* {
	-ms-flex:0 0 8.3333333%;
	flex:0 0 8.3333333%;
	max-width:8.3333333% 
}
@media (min-width: 576px) {
	.row-cols-sm-7>* {
		-ms-flex:0 0 14.285714%;
		flex:0 0 14.285714%;
		max-width:14.285714% 
   }
	.row-cols-sm-8>* {
		-ms-flex:0 0 12.5%;
		flex:0 0 12.5%;
		max-width:12.5% 
   }
	.row-cols-sm-9>* {
		-ms-flex:0 0 11.111111%;
		flex:0 0 11.111111%;
		max-width:11.111111% 
   }
	.row-cols-sm-10>* {
		-ms-flex:0 0 10%;
		flex:0 0 10%;
		max-width:10% 
   }
	.row-cols-sm-11>* {
		-ms-flex:0 0 9.090909%;
		flex:0 0 9.090909%;
		max-width:9.090909% 
   }
	.row-cols-sm-12>* {
		-ms-flex:0 0 8.3333333%;
		flex:0 0 8.3333333%;
		max-width:8.3333333% 
   }
}
@media (min-width: 768px) {
	.row-cols-md-7>* {
		-ms-flex:0 0 14.285714%;
		flex:0 0 14.285714%;
		max-width:14.285714% 
   }
	.row-cols-md-8>* {
		-ms-flex:0 0 12.5%;
		flex:0 0 12.5%;
		max-width:12.5% 
   }
	.row-cols-md-9>* {
		-ms-flex:0 0 11.111111%;
		flex:0 0 11.111111%;
		max-width:11.111111% 
   }
	.row-cols-md-10>* {
		-ms-flex:0 0 10%;
		flex:0 0 10%;
		max-width:10% 
   }
	.row-cols-md-11>* {
		-ms-flex:0 0 9.090909%;
		flex:0 0 9.090909%;
		max-width:9.090909% 
   }
	.row-cols-md-12>* {
		-ms-flex:0 0 8.3333333%;
		flex:0 0 8.3333333%;
		max-width:8.3333333% 
   }
}
@media (min-width: 992px) {
	.row-cols-lg-7>* {
		-ms-flex:0 0 14.285714%;
		flex:0 0 14.285714%;
		max-width:14.285714% 
   }
	.row-cols-lg-8>* {
		-ms-flex:0 0 12.5%;
		flex:0 0 12.5%;
		max-width:12.5% 
   }
	.row-cols-lg-9>* {
		-ms-flex:0 0 11.111111%;
		flex:0 0 11.111111%;
		max-width:11.111111% 
   }
	.row-cols-lg-10>* {
		-ms-flex:0 0 10%;
		flex:0 0 10%;
		max-width:10% 
   }
	.row-cols-lg-11>* {
		-ms-flex:0 0 9.090909%;
		flex:0 0 9.090909%;
		max-width:9.090909% 
   }
	.row-cols-lg-12>* {
		-ms-flex:0 0 8.3333333%;
		flex:0 0 8.3333333%;
		max-width:8.3333333% 
   }
}
@media (min-width: 1200px) {
	.row-cols-xl-7>* {
		-ms-flex:0 0 14.285714%;
		flex:0 0 14.285714%;
		max-width:14.285714% 
   }
	.row-cols-xl-8>* {
		-ms-flex:0 0 12.5%;
		flex:0 0 12.5%;
		max-width:12.5% 
   }
	.row-cols-xl-9>* {
		-ms-flex:0 0 11.111111%;
		flex:0 0 11.111111%;
		max-width:11.111111% 
   }
	.row-cols-xl-10>* {
		-ms-flex:0 0 10%;
		flex:0 0 10%;
		max-width:10% 
   }
	.row-cols-xl-11>* {
		-ms-flex:0 0 9.090909%;
		flex:0 0 9.090909%;
		max-width:9.090909% 
   }
	.row-cols-xl-12>* {
		-ms-flex:0 0 8.3333333%;
		flex:0 0 8.3333333%;
		max-width:8.3333333% 
   }
}
.tooltip {
	position:absolute;
	z-index:1070;
	display:block;
	margin:0;
	font-family:Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-style:normal;
	font-weight:400;
	line-height:1.5;
	text-align:left;
	text-align:start;
	text-decoration:none;
	text-shadow:none;
	text-transform:none;
	letter-spacing:normal;
	word-break:normal;
	word-spacing:normal;
	white-space:normal;
	line-break:auto;
	font-size:.875rem;
	word-wrap:break-word;
	opacity:0 
}
.tooltip.show {
	opacity:.9 
}
.tooltip .arrow {
	position:absolute;
	display:block;
	width:.8rem;
	height:.4rem 
}
.tooltip .arrow::before {
	position:absolute;
	content:"";
	border-color:transparent;
	border-style:solid 
}
.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
	padding:.4rem 0 
}
.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
	bottom:0 
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
	top:0;
	border-width:.4rem .4rem 0;
	border-top-color:#000 
}
.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
	padding:0 .4rem 
}
.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
	left:0;
	width:.4rem;
	height:.8rem 
}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
	right:0;
	border-width:.4rem .4rem .4rem 0;
	border-right-color:#000 
}
.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
	padding:.4rem 0 
}
.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
	top:0 
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
	bottom:0;
	border-width:0 .4rem .4rem;
	border-bottom-color:#000 
}
.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
	padding:0 .4rem 
}
.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
	right:0;
	width:.4rem;
	height:.8rem 
}
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
	left:0;
	border-width:.4rem 0 .4rem .4rem;
	border-left-color:#000 
}
.tooltip-inner {
	max-width:200px;
	padding:.25rem .5rem;
	color:#fff;
	text-align:center;
	background-color:#000;
	border-radius:.35rem 
}
.tooltip{
	--bs-tooltip-zindex:1080;
	--bs-tooltip-max-width:200px;
	--bs-tooltip-padding-x:0.5rem;
	--bs-tooltip-padding-y:0.25rem;
	--bs-tooltip-font-size:0.875rem;
	--bs-tooltip-color:#fff;
	--bs-tooltip-bg:#058343;
	--bs-tooltip-border-radius:0.375rem;
	--bs-tooltip-opacity:0.9;
	--bs-tooltip-arrow-width:0.8rem;
	--bs-tooltip-arrow-height:0.4rem;
	z-index:var(--bs-tooltip-zindex);
	display:block;
	padding:var(--bs-tooltip-arrow-height);
	margin:var(--bs-tooltip-margin);
	font-family:var(--bs-font-sans-serif);
	font-style:normal;
	font-weight:400;
	line-height:1.5;
	text-align:left;
	text-align:start;
	text-decoration:none;
	text-shadow:none;
	text-transform:none;
	letter-spacing:normal;
	word-break:normal;
	white-space:normal;
	word-spacing:normal;
	line-break:auto;
	font-size:var(--bs-tooltip-font-size);
	word-wrap:break-word;
	opacity:0 
}
.tooltip.show{
	opacity:var(--bs-tooltip-opacity) 
}
.tooltip .tooltip-arrow{
	display:block;
	width:var(--bs-tooltip-arrow-width);
	height:var(--bs-tooltip-arrow-height) 
}
.tooltip .tooltip-arrow:before{
	position:absolute;
	content:"";
	border-color:transparent;
	border-style:solid 
}
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,.bs-tooltip-top .tooltip-arrow{
	bottom:0 
}
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow:before,.bs-tooltip-top .tooltip-arrow:before{
	top:-1px;
	border-left-width:calc(var(--bs-tooltip-arrow-width)*0.5);
	border-bottom-width:0;
	border-right-width:calc(var(--bs-tooltip-arrow-width)*0.5);
	border-top-width:var(--bs-tooltip-arrow-height);
	border-top-color:var(--bs-tooltip-bg) 
}
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,.bs-tooltip-end .tooltip-arrow{
	left:0;
	width:var(--bs-tooltip-arrow-height);
	height:var(--bs-tooltip-arrow-width) 
}
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow:before,.bs-tooltip-end .tooltip-arrow:before{
	right:-1px;
	border-left-width:0;
	border-bottom-width:calc(var(--bs-tooltip-arrow-width)*0.5);
	border-right-width:var(--bs-tooltip-arrow-height);
	border-top-width:calc(var(--bs-tooltip-arrow-width)*0.5);
	border-right-color:var(--bs-tooltip-bg) 
}
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,.bs-tooltip-bottom .tooltip-arrow{
	top:0 
}
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow:before,.bs-tooltip-bottom .tooltip-arrow:before{
	bottom:-1px;
	border-left-width:calc(var(--bs-tooltip-arrow-width)*0.5);
	border-bottom-width:var(--bs-tooltip-arrow-height);
	border-right-width:calc(var(--bs-tooltip-arrow-width)*0.5);
	border-top-width:0;
	border-bottom-color:var(--bs-tooltip-bg) 
}
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,.bs-tooltip-start .tooltip-arrow{
	right:0;
	width:var(--bs-tooltip-arrow-height);
	height:var(--bs-tooltip-arrow-width) 
}
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow:before,.bs-tooltip-start .tooltip-arrow:before{
	left:-1px;
	border-left-width:var(--bs-tooltip-arrow-height);
	border-bottom-width:calc(var(--bs-tooltip-arrow-width)*0.5);
	border-right-width:0;
	border-top-width:calc(var(--bs-tooltip-arrow-width)*0.5);
	border-left-color:var(--bs-tooltip-bg) 
}
.tooltip-inner{
	max-width:var(--bs-tooltip-max-width);
	padding:var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
	color:var(--bs-tooltip-color);
	text-align:center;
	background-color:var(--bs-tooltip-bg);
	border-radius:var(--bs-tooltip-border-radius) 
}
.input-group.is-invalid .input-group-text {
	border: 1px solid #dc3545 !important;
}
.accordion-button {
	display: inline-block;
	font-weight: 400;
	color: #858796;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.35rem;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	padding: 0.25rem 0.5rem;
	font-size: .875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
	color: #fff;
	background-color: #06897c;
	border-color: #058376;
}
.accordion-button:focus {
	outline: none 
}
.navbar-nav::-webkit-scrollbar {
	width: 0px;
}
.navbar-nav::-webkit-scrollbar-track {
	background-color: transparent;
}
.navbar-nav::-webkit-scrollbar-thumb {
	background: transparent;
}
.filter-card .form-label, .filter-card input, .filter-card .form-control {
	font-size: 14px;
}
.table td.text-success {
	color: #04a31f !important;
}
.table td.text-danger {
	color: #e71d0a !important;
}
.table td.text-warning {
	color: #e17d00 !important;
}
.status-modal .modal-header, .status-modal .modal-footer {
	padding: 0.7rem 1rem;
}
.status-modal .modal-title {
	font-size: 20px;
}
.status-modal .btn-close {
	display: none;
}
.card.shadow .card-header {
	min-height: 55px;
	align-items: center;
}
.login-h1 {
	margin: 0;
	font-weight: 700;
	line-height: 1.5;
	font-size: 1.5rem;
	font-family: 'Inter',sans-serif;
}
@media (min-width: 900px) {
	.login-h1 {
		font-size: 1.75rem;
   }
}
@media (min-width: 1200px) {
	.login-h1 {
		font-size: 2rem;
   }
}
.login-h4 {
	margin: 0;
	font-weight: 700;
	line-height: 1.5;
	font-size: 1.25rem;
	font-family: 'Inter',sans-serif;
}
@media (min-width: 900px) {
	.login-h4 {
		font-size: 1.5rem;
   }
}
.heading1 {
	font-weight: 700;
	font-size: 1.125rem;
	font-family: 'Inter',sans-serif;
}
.heading2 {
	font-weight: 700;
	font-size: 1.35rem;
	font-family: 'Inter',sans-serif;
}
@media (min-width: 600px) {
	.heading2 {
		font-size: 1.75rem;
   }
}
@media (min-width: 900px) {
	.heading2 {
		font-size: 2rem;
   }
}
.main-banner {
	box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px;
	border-radius: 16px;
}
.artist-details .avatar {
	width: 128px;
	height: 128px;
	box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px;
	border: 1px solid rgba(0, 0, 0, 0.07);
	border-radius: 100px;
	object-fit: cover;
}
.artist-details .title {
	margin: 0;
	font-weight: 600;
	line-height: 1.5;
	font-size: 26px;
	font-family: 'Inter',sans-serif;
	margin-bottom: 6px;
	color: #212B36;
}
.artist-details .title1 {
	margin: 0;
	font-weight: 600;
	line-height: 1.5;
	font-size: 20px;
	font-family: 'Inter',sans-serif;
	margin-bottom: 10px;
	color: #212B36;
}
.artist-details .info {
	margin: 0px;
	line-height: 1.5;
	font-size: 0.875rem;
	font-family: 'Inter',sans-serif;
	font-weight: 400;
	margin-bottom: 10px;
	color: #6b7987;
	text-decoration: none;
}
.artist-details .info-title {
	margin: 0;
	line-height: 1.5;
	font-size: 14px;
	font-family: 'Inter',sans-serif;
	font-weight: 400;
	color: #6b7987;
	margin-bottom: 4px;
}
.artist-details .info-count {
	margin: 0;
	font-weight: 600;
	line-height: 1.5;
	font-size: 20px;
	font-family: 'Inter',sans-serif;
	color: #212B36;
}
.poster-details .details-card, .poster-details .info-card {
	background-color: #fff;
	color: #212B36;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px;
	border: 1px solid rgba(0, 0, 0, 0.07);
	overflow: hidden;
	position: relative;
	border-radius: 16px;
	padding: 10px;
}
.poster-details .slick-list, .poster-details .slick-slide {
	border-radius: 12px;
}
.poster-details .details-card .images {
	border-radius: 12px;
   /* cursor: zoom-in;
	*/
}
.poster-details .details-card .slider-btn {
	z-index: 9;
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 16px;
	right: 16px;
	color: #fff;
	border-radius: 8px;
	padding-left: 5px;
	padding-right: 5px;
	background-color: rgba(22, 28, 36, 0.48);
}
.poster-details .details-card .slider-btn p {
	font-size: 14px;
	font-weight: 500;
}
.poster-details .details-card .slider-btn span {
	cursor: pointer;
	position: relative;
	top: -1px;
	padding: 4px;
}
.poster-details .title {
	margin: 0;
	font-weight: 600;
	line-height: 1.5;
	font-size: 30px;
	font-family: 'Inter',sans-serif;
	margin-top: 5px;
	margin-bottom: 6px;
	color: #212B36;
}
.poster-details .info {
	margin: 0px;
	line-height: 1.5;
	font-size: 16px;
	font-family: 'Inter',sans-serif;
	font-weight: 400;
	margin-bottom: 8px;
	color: #212B36;
	text-decoration: none;
}
.poster-details .info b {
	color: #058343;
}
.poster-details .info span {
	display: inline-block;
	width: 25px;
}
.poster-details .info svg {
	color: #058343;
	position: relative;
	top: -2px;
	margin-right: 6px;
}
.poster-details .details-card .avatar {
	width: 50px;
	height: 50px;
	border-radius: 100px;
	margin-right: 15px;
	object-fit: cover;
}
.poster-details .details-card .artist-title {
	margin: 0;
	font-weight: 600;
	line-height: 1.5714285714285714;
	font-size: 16px;
	color: #212B36;
	font-family: 'Inter',sans-serif;
}
.poster-details .details-card .artist-info {
	margin: 0;
	line-height: 1.5;
	font-size: 13px;
	font-family: 'Inter',sans-serif;
	font-weight: 400;
	color: #919EAB;
}
.poster-details .MuiTabs-root {
	background-color: #F2F3F5;
}
.poster-details .MuiTab-root {
	color: #637381;
	font-family: 'Inter',sans-serif;
	font-weight: 500;
	text-transform: initial;
}
.poster-details .MuiTab-root.Mui-selected {
	color: #058343;
	font-weight: 600;
}
.poster-details .MuiTabs-indicator {
	background-color: #058343;
}
.poster-details .chat-item {
	display: flex;
	border-bottom: 1px solid rgb(219, 226, 239);
	padding: 10px 1rem;
}
.poster-details .donation-item {
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgb(219, 226, 239);
	padding: 10px 1rem;
}
.poster-details .chat-item .avatar, .poster-details .donation-item .avatar {
	width: 35px;
	height: 35px;
	border-radius: 100px;
	margin-right: 15px;
	margin-top: 5px;
	object-fit: cover;
}
.poster-details .chat-item .title, .poster-details .donation-item .title {
	margin: 0;
	font-weight: 600;
	line-height: 1.5714285714285714;
	font-size: 16px;
	color: #212B36;
	font-family: 'Inter',sans-serif;
}
.poster-details .chat-item .info {
	margin: 0;
	line-height: 1.5;
	font-size: 14px;
	font-family: 'Inter',sans-serif;
	font-weight: 500;
	color: #637381;
}
.poster-details .donation-item .info {
	margin: 0;
	line-height: 1.5;
	font-size: 14px;
	font-family: 'Inter',sans-serif;
	font-weight: 600;
	color: #058343;
	margin-left: 6px;
}
.poster-details .comment-input-box {
	background-color: #F2F3F5;
	height: 56px;
	position: relative;
	padding: 0px 16px;
}
.poster-details .comment-input-box input {
	background-color: #F2F3F5;
	border: none;
	color: #212B36;
	padding: 10px 50px 10px 5px;
	display: block;
	width: 100%;
	height: 56px;
}
.poster-details .comment-input-box input:hover, .poster-details .comment-input-box input:focus, .poster-details .comment-input-box input:active {
	outline: none;
	box-shadow: none;
}
.poster-details .comment-input-box .btn-send {
	border-radius: 100px;
	border: 0px;
	padding: 0px;
	height: 38px;
	width: 38px;
	min-width: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	position: absolute;
	top: 9px;
	right: 16px;
}
.poster-details .comment-input-box .btn-send, .poster-details .comment-input-box .btn-send:active, .poster-details .comment-input-box .btn-send:focus, .poster-details .comment-input-box .btn-send:hover {
	color: #fff;
	background-color: #058343;
}
.fixed-footer {
	box-shadow: 0px 0 10px rgb(0 0 0 / 10%);
	background: #ffffff;
	padding: 0.7rem 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 60px;
	z-index: 1;
}
@media (max-width:767px) {
	.mb-fixed-footer {
		padding-bottom: 60px;
   }
}
.artist-card {
	background-color: #fff;
	color: #212B36;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px;
	border: 1px solid rgba(0, 0, 0, 0.07);
	overflow: hidden;
	position: relative;
	border-radius: 16px;
	text-align: center;
}
.artist-card .avatar-shape {
	width: 144px;
	height: 62px;
	display: inline-block;
	background-color: currentColor;
	mask: url(https://minimal-assets-api-dev.vercel.app/assets/icons/shape-avatar.svg) no-repeat center/contain;
	-webkit-mask: url(https://minimal-assets-api-dev.vercel.app/assets/icons/shape-avatar.svg) no-repeat center/contain;
	z-index: 10;
	left: 0;
	right: 0;
	bottom: -26px;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	color: #fff;
}
.artist-card .avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	border-radius: 50%;
	overflow: hidden;
	width: 64px;
	height: 64px;
	z-index: 11;
	left: 0;
	right: 0;
	bottom: -32px;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
}
.artist-card .avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.artist-card .overlay {
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
	background-color: rgba(0, 82, 73, 0.8);
	top: 0;
	z-index: 8;
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
}
.artist-card .poster {
	width: 100%;
	line-height: 0;
	display: block;
	overflow: hidden;
	position: relative;
	padding-top: calc(100% / 16 * 9);
}
.artist-card .poster span, .poster-card .poster span {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	line-height: 0;
	position: absolute;
	background-size: cover!important;
}
.artist-card .poster img, .poster-card .poster img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.artist-card a {
	text-decoration: none;
}
.artist-card .title {
	font-weight: 600;
	line-height: 1.5;
	font-size: 1.125rem;
	font-family: 'Inter',sans-serif;
	margin-top: 48px;
	margin-bottom: 0px;
	color: #212B36;
	text-decoration: none;
}
.artist-card .info {
	margin: 0px;
	line-height: 1.5714285714285714;
	font-size: 0.875rem;
	font-family: 'Inter',sans-serif;
	font-weight: 400;
	color: #637381;
	text-decoration: none;
}
.artist-card .divider {
	margin: 0px;
	flex-shrink: 0;
	border-width: 0;
	border-color: rgba(145, 158, 171, 0.24);
	border-bottom-width: thin;
	border-style: dashed;
	margin-top: 20px;
}
.artist-card .info-title {
	margin: 0;
	line-height: 1.5;
	font-size: 0.75rem;
	font-family: 'Inter',sans-serif;
	font-weight: 400;
	color: #7f8c99;
	margin-bottom: 4px;
}
.artist-card .info-count {
	margin: 0;
	font-weight: 600;
	line-height: 1.5;
	font-size: 1.125rem;
	font-family: 'Inter',sans-serif;
	color: #212B36;
}
.poster-card a {
	text-decoration: none;
}
.poster-card {
	background-color: #fff;
	color: #212B36;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px;
	border: 1px solid rgba(0, 0, 0, 0.07);
	overflow: hidden;
	position: relative;
	border-radius: 16px;
}
.poster-card .poster {
	width: 100%;
	line-height: 0;
	display: block;
	overflow: hidden;
	position: relative;
	padding-top: 100%;
	border-radius: 12px 12px 0px 0px;
	margin-bottom: 10px;
}
.poster-card .title {
	margin: 0;
	font-weight: 600;
	line-height: 1.5;
	font-size: 1.125rem;
	font-family: 'Inter',sans-serif;
	margin-bottom: 6px;
	color: #212B36;
	text-decoration: none;
}
.poster-card .info {
	margin: 0;
	line-height: 1.5;
	font-size: 0.75rem;
	font-family: 'Inter',sans-serif;
	font-weight: 400;
	color: #7f8c99;
	margin-bottom: 14px;
	text-decoration: none;
}
.poster-card .avatar {
	width: 30px;
	height: 30px;
	border-radius: 100px;
	margin-right: 15px;
	object-fit: cover;
}
.poster-card .artist-title {
	margin: 0;
	font-weight: 600;
	line-height: 1.5714285714285714;
	font-size: 0.875rem;
	color: #212B36;
	font-family: 'Inter',sans-serif;
}
.poster-card .artist-info {
	margin: 0;
	line-height: 1.5;
	font-size: 0.75rem;
	font-family: 'Inter',sans-serif;
	font-weight: 400;
	color: #919EAB;
}
.carousel-btn {
	width: 28px;
	height: 28px;
	padding: 0px;
	cursor: pointer;
	border-radius: 50%;
	display: inline-flex;
	margin-left: 5px;
	align-items: center;
	justify-content: center;
	color: #637381;
	transition:background-color .15s ease-in-out 
}
.carousel-btn:hover {
	color: #212B36;
	background-color: rgba(99, 115, 129, 0.08);
}
.navbar-search {
	position: relative;
	overflow: hidden;
	padding-left: 3px;
	width: 100%;
}
.navbar-search .navbar-search-input-wrapper {
	transition: transform .3s ease;
	transform: translateX(100vw);
	width: 100%;
	position: relative;
	display: block;
}
@media (max-width: 575px) {
	.d-brand-none {
		display: none;
   }
}
@media (min-width: 576px) {
	.navbar-search .navbar-search-input-wrapper {
		transform: translateX(240px);
		width: 240px;
   }
}
@media (min-width: 650px) {
	.navbar-search .navbar-search-input-wrapper {
		transform: translateX(315px);
		width: 315px;
   }
}
@media (min-width: 750px) {
	.navbar-search .navbar-search-input-wrapper {
		transform: translateX(345px);
		width: 345px;
   }
}
@media (min-width: 950px) {
	.navbar-search .navbar-search-input-wrapper {
		transform: translateX(450px);
		width: 450px;
   }
}
.navbar-search.active .navbar-search-input-wrapper {
	transform: translateX(0px);
}
.navbar-search input {
	border-radius: 100px;
	background-color: #F2F3F5;
	border: 1px solid #F2F3F5;
	padding-left: 32px;
	padding-right: 45px;
}
.navbar-search input:focus, .navbar-search input:active {
	background-color: #F2F3F5;
}
.navbar-search-btn-wrapper {
	position: absolute;
	top: 0px;
	right: 0px;
	border-radius: 100px;
	border: 0px;
	padding: 0px;
	height: 38px;
	width: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition:background-color .15s ease, color .15s ease 
}
.navbar-search .navbar-search-btn-wrapper {
	color: #212B36;
	background-color: #eaecf4;
}
.navbar-search .navbar-search-btn-wrapper:active, .navbar-search .navbar-search-btn-wrapper:focus, .navbar-search .navbar-search-btn-wrapper:hover {
	color: #212B36;
	background-color: #eaecf4;
}
.navbar-search.active .navbar-search-btn-wrapper {
	color: #fff;
	background-color: #01315a;
}
.navbar-search.active .navbar-search-btn-wrapper:active, .navbar-search.active .navbar-search-btn-wrapper:focus, .navbar-search.active .navbar-search-btn-wrapper:hover {
	color: #fff;
	background-color: #01315a;
}
.navbar-search .navbar-search-x {
	position: absolute;
	top: -1px;
	left: 10px;
	height: 38px;
	align-items: center;
	display: flex;
	color: #6b7987;
}
.btn-tab, .btn-outline-tab {
	font-weight: 500;
	margin: 0px 7px;
}
.btn-tab svg, .btn-outline-tab svg, .btn-svg svg {
	position: relative;
	top: -2px;
	margin-right: 8px;
}
.btn-tab {
	color: #fff;
	background-color: #058343;
	border-color: #047c3f;
}
.btn-tab:hover {
	color:#fff;
	background-color:#026633;
	border-color:#026633;
}
.btn-tab.focus, .btn-tab:focus {
	box-shadow:none 
}
.btn-outline-tab {
	color:#058343;
	background-color: #f8f9fc;
	border-color:#058343;
}
.btn-outline-tab:hover {
	color:#058343;
	background-color:#ffffff;
	border-color:#058343;
}
.btn-outline-tab.focus, .btn-outline-tab:focus {
	box-shadow:none 
}
.row-cols-card>* {
	-ms-flex:0 0 100%;
	flex:0 0 100%;
	max-width:100% 
}
@media (min-width: 550px) {
	.row-cols-card>* {
		-ms-flex:0 0 50%;
		flex:0 0 50%;
		max-width:50% 
   }
}
@media (min-width: 800px) {
	.row-cols-card>* {
		-ms-flex:0 0 33.333333%;
		flex:0 0 33.333333%;
		max-width:33.333333% 
   }
}
@media (min-width: 1050px) {
	.row-cols-card>* {
		-ms-flex:0 0 25%;
		flex:0 0 25%;
		max-width:25% 
   }
}
@media (min-width: 1300px) {
	.row-cols-card>* {
		-ms-flex:0 0 20%;
		flex:0 0 20%;
		max-width:20% 
   }
}
.MuiTab-root, .MuiTab-root:focus, .MuiTab-root:active, .MuiTab-root:hover {
	outline: none;
}
.general-card {
	background-color: #fff;
	color: #212B36;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px;
	border: 1px solid rgba(0, 0, 0, 0.07);
	overflow: hidden;
	position: relative;
	border-radius: 16px;
	border: 1px solid #dbe2ef;
}
.general-card-header {
	padding: 15px 1rem;
	min-height: 48px;
	background-color: #484a54;
}
.general-card-header .heading1 {
	font-weight: 600;
	color: #f7f5f3;
	line-height: 1.4;
}
.general-card-header .go-back {
	font-weight: 500;
	line-height: 24px;
	font-size: 15px;
	color: #e4ded6;
	font-family: 'Inter',sans-serif;
	text-decoration: none;
}
.general-card .artist-item {
	display: flex;
	border-bottom: 1px solid rgb(219, 226, 239);
	padding: 15px 1rem;
	align-items: center !important;
}
.general-card .artist-item .avatar {
	width: 50px;
	height: 50px;
	border-radius: 100px;
	margin-right: 15px;
	object-fit: cover;
}
.general-card .artist-item .artist-title {
	margin: 0;
	font-weight: 600;
	line-height: 1.5714285714285714;
	font-size: 16px;
	color: #212B36;
	font-family: 'Inter',sans-serif;
	display: inline-block;
}
.general-card .artist-item .artist-info {
	margin: 0;
	line-height: 1.5;
	font-size: 13px;
	font-family: 'Inter',sans-serif;
	display: inline-block;
	font-weight: 400;
	color: #919EAB;
}
.general-card .artist-item .btn-star {
	border-radius: 100px;
	border: 0px;
	padding: 0px;
	height: 38px;
	width: 38px;
	min-width: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	color: #fff;
	background-color: #c96906;
}
.donate-package-item {
	display: flex;
	align-items: center;
	border: 1px solid #aaa;
	border-radius: 10px;
	padding: 6px 8px;
	cursor: pointer;
	margin-bottom: 15px;
}
.donate-package-item.active {
	background: #9fe5c1;
	box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px;
	border: 1px solid #058343;
}
.donate-package-item .icon {
	display: flex;
	width: 28px;
	height: 28px;
	justify-content: center;
	align-items: center;
	color: #212B36;
	user-select: none;
}
.donate-package-item .title {
	margin: 0;
	font-weight: 500;
	line-height: 1.5;
	font-size: 16px;
	font-family: 'Inter',sans-serif;
	margin-right: 10px;
	margin-left: 10px;
	color: #212B36;
	user-select: none;
}
.donate-package-item .price {
	margin: 0;
	font-weight: 600;
	line-height: 1.5;
	font-size: 16px;
	font-family: 'Inter',sans-serif;
	margin-left: auto;
	color: #212B36;
	user-select: none;
}
.quantity-container .quantity-btn {
	color: #212B36;
	background-color: #eaecf4;
	display: flex;
	width: 34px;
	height: 34px;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	cursor: pointer;
	transition:background-color .15s ease, color .15s ease;
	user-select: none;
}
.quantity-container .quantity-btn:hover {
	color: #ffffff;
	background-color: #058343;
}
.quantity-container .count {
	margin: 0;
	font-weight: 500;
	line-height: 1;
	font-size: 22px;
	font-family: 'Inter',sans-serif;
	min-width: 35px;
	margin-right: 10px;
	margin-left: 10px;
	color: #212B36;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
}
.donate-card .total-price {
	margin: 0;
	font-weight: 600;
	line-height: 1;
	font-size: 30px;
	font-family: 'Inter',sans-serif;
	min-width: 40px;
	color: #058343;
	-webkit-user-select: none;
	user-select: none;
}
.donate-card .col-poster {
	flex: 0 0 260px;
	max-width: 260px;
}
@media (min-width:576px) {
	.donate-card .col-card {
		flex: 0 0 575px;
		max-width: 575px;
   }
	.donate-card .col-poster {
		flex: 0 0 230px;
		max-width: 230px;
   }
	.donate-card .col-package {
		flex: 0 0 calc(100% - 230px);
		max-width: calc(100% - 230px);
   }
}
@media (min-width:768px) {
	.donate-card .col-card {
		flex: 0 0 700px;
		max-width: 700px;
   }
	.donate-card .col-poster {
		flex: 0 0 250px;
		max-width: 250px;
   }
	.donate-card .col-package {
		flex: 0 0 calc(100% - 250px);
		max-width: calc(100% - 250px);
   }
	.donate-package-item .title {
		font-size: 17px;
   }
	.donate-package-item .price {
		font-size: 17px;
   }
}
.donate-card .heading1 {
	font-weight: 600;
	font-size: 16px;
	font-family: 'Inter',sans-serif;
	margin-bottom: 10px;
}
.donation-history-card .item {
	display: flex;
	border-bottom: 1px solid rgb(219, 226, 239);
	padding: 15px 1rem;
	align-items: center !important;
}
.donation-history-card .item .avatar {
	width: 80px;
	height: 100px;
	object-fit: cover;
	border-radius: 10px;
	margin-right: 15px;
	object-fit: cover;
}
.donation-history-card .item .title {
	margin: 0;
	font-weight: 600;
	line-height: 1.5714285714285714;
	font-size: 18px;
	color: #212B36;
	font-family: 'Inter',sans-serif;
	display: inline-block;
	margin-bottom: 3px;
}
.donation-history-card .item .package {
	margin: 0;
	line-height: 1.5;
	font-size: 16px;
	font-family: 'Inter',sans-serif;
	display: inline-block;
	font-weight: 500;
	margin-bottom: 5px;
	color: #058343;
}
.donation-history-card .item .date {
	margin: 0;
	line-height: 1.5;
	font-size: 14px;
	font-family: 'Inter',sans-serif;
	display: inline-block;
	font-weight: 400;
	color: #6b7987;
}
.fake-label{
	color: rgba(0, 0, 0, 0.7);
	font-family: 'Inter',sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.4375em;
	letter-spacing: 0.00938em;
	display: block;
	margin-bottom: 5px;
}
.font-18 {
	font-size: 18px;
}
.exchange-input input {
	font-family: 'Inter',sans-serif;
	height: initial;
	padding: 10px;
	font-size: 20px;
	font-weight: 500;
	width: 100px;
	text-align: right;
}
.accordian-faq {
	box-shadow: none !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.accordian-faq:before {
	opacity: 0 !important;
}
.accordian-faq.Mui-expanded {
	margin: 0px !important;
}
.MuiAccordionSummary-root.Mui-expanded {
	min-height: 48px !important;
}
.MuiAccordionSummary-content.Mui-expanded {
	margin: 12px 0 !important;
}
.donate-checkbox {
	display: flex;
	align-items: flex-start;
	margin-bottom: 10px;
}
.donate-checkbox .MuiCheckbox-root {
	padding: 0px;
	margin-right: 8px;
}
.donate-checkbox p {
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 0px;
	user-select: none;
	display: inline;
}
.footer {
	border-top: 1px solid rgba(145, 158, 171, 0.24);
	padding-top: 25px;
	padding-bottom: 15px;
	background-color: #ffffff;
}
.footer-grid {
	box-sizing: border-box;
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	justify-content: center;
	text-align: center;
}
@media (min-width: 900px) {
	.footer-grid {
		justify-content: space-between;
		text-align: left;
   }
}
.footer-info {
	font-size: 15px;
	line-height: 1.5;
	margin-bottom: 0px;
	margin-left: 15px;
}
.footer-heading {
	margin: 0;
	font-weight: 600;
	line-height: 1.4;
	font-size: 14px;
	color: #212B36;
	font-family: 'Inter',sans-serif;
	display: block;
	margin-top: 20px;
	margin-bottom: 2px;
	text-transform: uppercase;
}
.footer-link {
	margin-bottom: 8px;
	margin-right: 35px;
	line-height: 1.4;
	font-weight: 500;
	font-size: 14px;
	color: #555555;
	font-family: 'Inter',sans-serif;
}
.footer-link a {
	margin: 0;
	font-weight: 500;
	line-height: 1.4;
	font-size: 14px;
	color: #555555;
	font-family: 'Inter',sans-serif;
	text-decoration: none;
}
.footer-link a:hover, .footer-link a:active, .footer-link a:focus {
	color: #333333;
	text-decoration: none;
}
.footer-foot {
	margin-top: 15px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 14px;
	text-align: center;
	border-top: 1px solid rgba(145, 158, 171, 0.24);
}
.point-item{
	position: relative;
	overflow: hidden;
}
.point-item:not(.end)::before{
	content: "";
	position: absolute;
	border: 2px dashed #f7ca92;
	height: 100%;
	left: 18px;
	top: 20px;
}
.point-number {
	height: 40px;
	width: 40px;
	min-height: 40px;
	min-width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 19px;
	background-color: #c96906;
	border-radius: 100%;
	margin: 0;
	line-height: 1;
	z-index: 1;
}
.point-container .badge {
	font-weight: normal;
	font-size: 14px;
	border-radius: 100px;
	padding: 8px 15px;
	margin-right: 10px;
	margin-bottom: 0px;
	background-color: #484a54;
	color: #f5f5f5;
}
.badge-checkbox {
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	padding: 6px 13px;
	/* min-width: 80px; */
	border: 1px solid #c8c2c2;
	cursor: pointer;
	margin-right: 8px;
	user-select: none;
	display: inline-block;
	border-radius: 4px;
	text-align: center;
	min-width: 60px;
	margin-bottom: 8px;
}
.badge-checkbox.light {
	background-color: #f8f9fc;
}
.badge-checkbox.success {
	background-color: #00AB55;
	border-color: #00AB55;
	color: #f5f5f5;
}
.badge-checkbox.danger {
	background-color: #e10c23;
	border-color: #e10c23;
	color: #f5f5f5;
}
.badge-checkbox.primary {
	background-color: #01315a;
	border-color: #01315a;
	color: #f5f5f5;
}
.mowing-history-image {
	width: 90px;
	margin-right: 10px;
	margin-top: 10px;
	border-radius: 8px;
	box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69,.2);
}
.MuiInputLabel-root.MuiInputLabel-shrink {
	color: rgba(0, 0, 0, 0.8) !important;
	font-weight: 500 !important;
}
.border-left-primary {
	border-left:.25rem solid #103dbf !important
}
.border-left-secondary {
	border-left:.25rem solid #858796 !important
}
.border-left-success {
	border-left:.25rem solid #058343 !important
}
.border-left-info {
	border-left:.25rem solid #06897c !important
}
.border-left-warning {
	border-left:.25rem solid #de760b !important
}
.border-left-danger {
	border-left:.25rem solid #e74a3b !important
}
.border-left-light {
	border-left:.25rem solid #f8f9fc !important
}
.border-left-dark {
	border-left:.25rem solid #5a5c69 !important
}